


import { updateSSML } from '../utils/ttstools'

const tagName = 'say-as';
const attributes = ['data-label', 'interpret-as'];
const commandName = `ssml-${tagName}`;


export default {
    name: commandName,
    display: 'command',
    innerHTML: '<i class="mif-qa"></i>',
    buttonClass: 'button tbtn-say-as',
    title: 'Say As',

    add: function (core, targetElement) {
        const context = core.context;
        const span = document.createElement('span')
        context[commandName] = {
            targetButton: targetElement,
            targetText: span
        };

        span.className = 'txt';
        span.innerText = `[${tagName}]`;
        targetElement.appendChild(span);
    },

    active: function (element) {
        const nodeSpeech = VoiceEditor.getNodeSpeech();
        if (!element) {
            this.util.changeTxt(this.context[commandName].targetText, `[${tagName}]`);
            this.util.removeClass(this.context[commandName].targetButton, 'active');
        } else if (nodeSpeech && nodeSpeech['say-as']) {
            this.util.changeTxt(this.context[commandName].targetText, element.getAttribute(tagName));
            this.util.addClass(this.context[commandName].targetButton, 'active');
            return true;
        }

        return false;
    },
    action: function () {
        //if (!/^BUTTON$/i.test(e.target.tagName)) return false;
        let curRange = this.getRange();
        if (curRange.collapsed) {
            if (!curRange.startContainer.parentElement.innerText.trim()) return;
            $(curRange.startContainer.parentElement).selectText();
            curRange = this.getRange();
        }


        openSayAsDialog();





    }
}

function openSayAsDialog() {
    const actions = [];
    const vList = [
        { text: 'Cardinal', value: 'cardinal' },
        { text: 'Ordinal', value: 'ordinal' },
        { text: 'Digits', value: 'digits' },
        { text: 'Fraction', value: 'fraction' },
        { text: 'Unit', value: 'unit' },
        { text: 'Date', value: 'date' },
        { text: 'Time', value: 'time' },
        { text: 'Address', value: 'address' },
        { text: 'Expletive', value: 'expletive' },
        { text: 'Telephone', value: 'telephone' },
        { text: '', value: '', cls: 'btn-empty' },
        { text: 'Clear', value: '', cls: 'alert' },
    ];

    for (let opt of vList) {

        const action = {
            caption: opt.text,
            cls: "js-dialog-close button success outline btn-dlg tooltip " + (opt.cls || ''),
            onclick: () => setInterpretation(opt.value)
        }
        actions.push(action);
    }


    const dialog = Metro.dialog.create({
        title: "Interpret as",
        content: "",
        clsDialog: 'say-as-modal toolbar-dlg',
        overlayClickClose: true,
        overlayAlpha: 0.1,
        actions
    });

    dialog.find('button.btn-empty').attr('disabled', 'disabled');

    const curRange = VoiceEditor.sunEditor.core.getRange()
    if (!curRange.collapsed) {
        const brect = curRange.getBoundingClientRect();
        const dlgWidth = dialog.width();
        dialog.offset({ top: brect.y + 30, left: Math.min(brect.x, window.innerWidth - dlgWidth) });
    }

}

function setInterpretation(level) {
    //VoiceEditor.sunEditor.core.history.push();
    //VoiceEditor.sunEditor.core.history._lock = true;
    VoiceEditor.setNode({ "say-as": level })
    //VoiceEditor.sunEditor.core.history._lock = false;
    updateSSML(tagName);
    //VoiceEditor.sunEditor.core.history.push();
}

