const path = require('path');
import plugin_ssml_emphasis from './plugins/emphasis'
import plugin_ssml_sayAs from './plugins/say-as'
import plugin_ssml_volume from './plugins/prosody-volume'
import plugin_ssml_pitch from './plugins/prosody-pitch'
import plugin_ssml_rate from './plugins/prosody-rate'
import plugin_ssml_sub from './plugins/sub'
import plugin_ssml_breath from './plugins/breath'
import plugin_ssml_break from './plugins/break'
import plugin_ssml_clearformat from './plugins/clearformat'
import plugin_adv_prosody from './plugins/adv-prosody'
import * as titlebar from '../window/titlebar'
import ProsodyEditor from './ProsodyEditor'
import { applyOverrides } from './overrides/suneditor.overrides.js'
import { updateTTSChunks, prepareTTSChunk, prepareTTSRequest, settings, genID, setSelectedVoice, getVoiceLabel } from './utils/ttstools'
import * as NLP from './utils/nlp'
import RPC from '../rpc'
import api from '../api'
import AppSettings from '../settings';
import { addGlobalCSS } from './utils/style';
import User from '../user';

const { xml2json, json2xml } = require('xml-js');

window.updateTTSChunks = updateTTSChunks;

window.prepareTTSRequest = prepareTTSRequest;

// const editorEvents = {
//     voiceChange: () => { }
// }
const VoiceEditor: any = {}

VoiceEditor.file = {

}

VoiceEditor._events = {};
VoiceEditor.on = function (name, cb) {
    if (!VoiceEditor._events[name]) VoiceEditor._events[name] = [];
    if (typeof cb == 'function') VoiceEditor._events[name].push(cb);
}
VoiceEditor.trigger = function (name, ...args) {
    if (!VoiceEditor._events[name]) return;
    for (let cb of VoiceEditor._events[name])
        cb.apply(VoiceEditor, args);
}



let ready = false;


VoiceEditor.init = async () => {

    const actorsList = await VoiceEditor.loadActors();
    settings.voiceList;
    actorsList.forEach(voice => {
        if (!voice.name) return;
        settings.voiceList[voice.name] = voice;
    });




    VoiceEditor.sunEditor = window.sunEditor = SUNEDITOR.create(document.getElementById('ttsEditor'), {
        toolbarContainer: '#toolbar',
        callBackSave: VoiceEditor.saveFile,
        shortcutsDisable: ['bold', 'strike', 'underline', 'italic', 'undo', 'indent', 'save'],
        plugins: [plugin_ssml_emphasis, plugin_ssml_sayAs, plugin_ssml_volume, plugin_ssml_sub, plugin_ssml_pitch,
            plugin_ssml_rate, plugin_ssml_breath, plugin_ssml_clearformat, plugin_adv_prosody, plugin_ssml_break],
        buttonList: [
            ['ssml-pitch', 'ssml-rate'],
            ['ssml-emphasis', 'ssml-say-as'],
            ['ssml-breath', 'ssml-break'],
            ['ssml-clearformat']
        ],
        addTagsWhitelist: "tts|sub|breath|break|prosody|say-as|emphasis|br",
        pasteTagsWhitelist: 'span|tts|breath|break|prosody|say-as|emphasis|p|sub|br|ul|ol|li|h1|h2|h3|h4|h5',
        attributesWhitelist: {
            tts: 'emphasis|volume|pitch|rate|say-as|sub|data-label|fx',
            span: 'id|class|voice|lang|fx|data-label|data-dur|data-pitch',
            breath: 'id|class|data-label',
            break: 'id|class|data-label|time',
            prosody: 'rate|data-label',
            "say-as": 'interpret-as|data-label',
            emphasis: 'level|data-label'
        }
    });

    //sunEditor.core.editorTagsWhitelistRegExp = /<\/?\b(?!\bprosody\b|\bsay-as\b|\bemphasis\b|\bbr\b|\bp\b|\bdiv\b|\bpre\b|\bblockquote\b|\bh[1-6]\b|\bol\b|\bul\b|\bli\b|\bhr\b|\bfigure\b|\bfigcaption\b|\bimg\b|\biframe\b|\baudio\b|\bvideo\b|\bsource\b|\btable\b|\bthead\b|\btbody\b|\btr\b|\bth\b|\btd\b|\ba\b|\bb\b|\bstrong\b|\bvar\b|\bi\b|\bem\b|\bu\b|\bins\b|\bs\b|\bspan\b|\bstrike\b|\bdel\b|\bsub\b|\bsup\b|\bcode\b|\bsvg\b|\bpath\b)[^>]*>/gi
    //VoiceEditor.sunEditor.core.pasteTagsWhitelistRegExp = /<\/?\b(?!\tts\b|\bspan\b|\bbr\b|\bp\b)[^>]*>/gi
    //sunEditor.core._attributesWhitelistRegExp = /((?:id|data-label|level|rate|interpret-as|contenteditable|colspan|rowspan|target|href|download|rel|src|alt|class|type|controls|data-format|data-size|data-file-size|data-file-name|data-origin|data-align|data-image-link|data-rotate|data-proportion|data-percentage|origin-size|data-exp|data-font-size)s*=s*"[^"]*")/gi



    //patch for isSameAttribute in order to handle spans with same id correctly
    applyOverrides(sunEditor);

    sunEditor.onload = function () {
        updateTTSChunks();
        sunEditor.core.history.reset(false)
        VoiceEditor.sunEditor.core.history.stack = []

        document.querySelector('.sun-editor').style.width = '';
        document.querySelector('.sun-editor .sun-editor-editable').style.height = '';
        document.querySelector('.sun-editor .sun-editor-editable').classList.add('mousetrap');

        document.querySelector('.sun-editor .sun-editor-editable').addEventListener('mouseup', snapSelectionToWord);
    }

    // sunEditor.onPaste = function (event, cleanData, maxCharCount, core) {
    //     //console.log(cleanData);
    //     return true;
    //     const range = sunEditor.core.getRange();

    //     console.log(range.commonAncestorContainer);
    //     if (range.commonAncestorContainer.nodeName !== 'DIV') {
    //         setTimeout(() => updateTTSChunks(true), 10);
    //         return true; //let sun editor handle it
    //     }


    //     range.deleteContents();
    //     const div = document.createElement('div');
    //     div.innerHTML = cleanData;
    //     range.insertNode(div);


    //     let parent = div.parentElement;
    //     console.log('parent = ', parent);




    //     div.childNodes.forEach(node => {
    //         if (node.nodeType == 3) {
    //             const p = document.createElement('p');
    //             const span = document.createElement('span');
    //             span.classList.add('tts-chunk');
    //             span.classList.add(settings.curVoice);
    //             span.setAttribute('voice', settings.curVoice);

    //             const voice = settings.voiceList[settings.curVoice];
    //             const label = voice.isRTL ? (voice.displayRTL || voice.displayLTR || settings.curVoice) : (voice.displayLTR || settings.curVoice);
    //             span.setAttribute('data-label', label);

    //             p.appendChild(span);
    //             div.insertBefore(p, node);
    //             span.innerText = node.textContent;
    //             node.remove();
    //         }
    //         if (node.nodeName == 'SPAN') {
    //             node.outerHTML = `<p>${node.outerHTML}</p>`;
    //         }
    //         if (node.nodeName == 'P') {
    //             if (node.firstChild.nodeName != 'SPAN') {
    //                 node.innerHTML = `<span>${node.innerHTML}</span>`
    //             }
    //         }
    //     })


    //     parent.querySelectorAll('span').forEach(s => {
    //         if (s.innerText.trim() == '') s.remove();
    //     })
    //     parent.querySelectorAll('p').forEach(p => {
    //         if (p.innerText.trim() == '') p.remove();
    //         // else {
    //         //     p.outerHTML = p.innerHTML + '<br />'
    //         // }
    //     })
    //     div.outerHTML = div.innerHTML;



    //     document.querySelectorAll('.sun-editor-editable > br').forEach(br => br.remove())




    //     return false;
    // }

    const statusBar = document.querySelector('.se-navigation');
    const generateBtn = $('#generateBtn')
    sunEditor.onChange = function (contents, core) {
        generateBtn.show();
        document.querySelectorAll('.tts-chunk').forEach(node => node.parentElement.setAttribute('data-credit', ''));
        VoiceEditor.calcCredits();
        statusBar.setAttribute('data-text', 'Text length:' + sunEditor.getCharCount());
        const curRange = sunEditor.core.getRange();

        if (curRange.startContainer && curRange.startContainer.parentElement) {
            const p = curRange.startContainer.parentElement.closest('p');
            if (p) {
                const text = p.innerText;
                const span = p.querySelector('.tts-chunk');
                if (span) {
                    const actor = span.getAttribute('voice');
                    NLP.lang(text).then(lang => VoiceEditor.trigger('textlang', lang, actor));
                }

            }
        }

        const textContent = document.querySelector('.sun-editor-editable').innerText.trim();


        let changed = false;
        if (!VoiceEditor.file.loadedTime || Date.now() - VoiceEditor.file.loadedTime > 500) {
            if (VoiceEditor.file.path) {
                const editContent = VoiceEditor.getContent();
                changed = (editContent != VoiceEditor.file.content);
                titlebar.setChanged(changed);
            }
            else {
                if (textContent) {
                    changed = true;
                    titlebar.setChanged(changed);
                }
            }


            VoiceEditor.file.changed = VoiceEditor.file.changed || changed;
        }
    }

    $('.sun-editor-editable').on('click', '.tts-chunk', function (e) {
        //console.log('dblclick .tts-chunk', $(this));
        const text = $(this).text();

        const actor = $(this).attr('voice');
        NLP.lang(text).then(lang => VoiceEditor.trigger('textlang', lang, actor));
        VoiceEditor.trigger('chunkClick', this);



        // if (!modifier.ctrl) return;
        // $(this).selectText();
        // e.preventDefault();
        // e.stopPropagation();
    });

    $('.sun-editor-editable').on('click', '.SSML', function (e) {
        if (!modifier.ctrl) return;
        //console.log('dblclick .SSML', $(this));
        $(this).selectText();
        e.preventDefault();
        e.stopPropagation();
    });

    $('.sun-editor-editable').on('dblclick', '.SSML', function (e) {
        if (!modifier.ctrl) return;
        //console.log('dblclick .SSML', $(this));
        $(this).closest('.tts-chunk').selectText();
        e.preventDefault();
        e.stopPropagation();

    });

    updateTTSChunks();

    VoiceEditor.prosodyEditor = new ProsodyEditor('#CSContainer');

    if (typeof AppSettings.userSettings.editor.snap == 'undefined') AppSettings.userSettings.editor.snap = true;
    VoiceEditor.snapSelection(AppSettings.userSettings.editor.snap);
    ready = true;
}

//===== [General events] ====================================

const modifier = {
    ctrl: false,
    alt: false
}

$(document).on('keydown', function (event) {
    //console.log(event.which)
    if (event.which == "17")
        modifier.ctrl = true;

    if (event.which == "18")
        modifier.alt = true;

    if (event.which == "13" && !event.shiftKey) //enter
        handleEnterKey();


});

$(document).on('keyup', function (event) {
    modifier.ctrl = false;
    modifier.alt = false;

    if (event.which == 8 || event.which == 46) {

        document.querySelectorAll('.sun-editor-editable p').forEach(p => {
            if (p.innerText.trim() == '') {
                if (!p.nextSibling) {
                    const label = getVoiceLabel(settings.curVoice);
                    p.innerHTML = `<span class="tts-chunk v-${settings.curVoice}" voice="${settings.curVoice}" data-label="${label}">&nbsp;</span>`;
                }
                // else {
                //     p.remove();
                // }
            }

        });


        document.querySelectorAll(".sun-editor-editable span:not(.tts-chunk)").forEach(span => {
            if (!span.hasAttribute('voice')) {
                if (span.previousSibling && span.previousSibling.nodeName == 'SPAN' && span.previousSibling.hasAttribute('voice')) {
                    //This fixes the case where the line break of a span is deleted by hitting backspace on the next span
                    span.previousSibling.append(span);
                    span.outerHTML = span.innerHTML;
                    return;
                }

                if (span.previousSibling && span.previousSibling.previousSibling && span.previousSibling.previousSibling.nodeName == 'SPAN' && span.previousSibling.previousSibling.hasAttribute('voice')) {
                    span.previousSibling.previousSibling.append(span.previousSibling);
                    span.previousSibling.append(span);
                    span.outerHTML = span.innerHTML;

                }
            }

        })
    }
});

function handleEnterKey() {
    setTimeout(() => {
        updateTTSChunks();
        VoiceEditor.setFx();
    }, 10);
}

VoiceEditor.loadActors = async (lang, reload) => {
    if (!lang && !reload) {
        if (AppSettings.cache.actorsList) {

            if (!lang) {
                setTimeout(() => {
                    VoiceEditor.loadActors(lang, true); // force cache
                }, 100);
            }
            return AppSettings.cache.actorsList;
        }
    }



    const result = await api.post(AppSettings.appSettings.actorsEndpoint, { lang }).catch(error => {
        console.error(error);
    });


    if (!lang) {
        AppSettings.cache.actorsList = result;
        AppSettings.saveCache();
    }

    return result;
}


VoiceEditor.ready = () => {
    return new Promise(resolve => {
        if (ready) return resolve(true);

        const itv = setInterval(() => {
            if (ready) {
                clearInterval(itv);
                resolve(true);
            }
        }, 30)
    })
}

VoiceEditor.getVoice = () => {
    return settings.curVoice;
}

function updateVoiceProsody(span) {

}
VoiceEditor.setVoice = (voiceId) => {
    if (!voiceId) return;

    setSelectedVoice(voiceId);
    const sunEditor = VoiceEditor.sunEditor;
    const curRange = sunEditor.core.getRange();

    const tagId = 'voiceId-' + Date.now();

    let containers = [];
    const startContainer = curRange.startContainer.nodeType == 3 ? curRange.startContainer.parentElement.closest('p') : curRange.startContainer.closest('p');
    const endContainer = curRange.endContainer.nodeType == 3 ? curRange.endContainer.parentElement.closest('p') : curRange.endContainer.closest('p');

    let curContainer = startContainer;
    containers.push(curContainer);

    let sanityCounter = 100;
    while (curContainer != endContainer && sanityCounter > 0) {
        sanityCounter--;

        curContainer = curContainer.nextSibling;
        containers.push(curContainer);
    }


    sunEditor.core.history.push();
    sunEditor.core.history._lock = true;

    containers.forEach(p => {
        p.querySelectorAll('.tts-chunk').forEach(span => {
            span.setAttribute('voice', voiceId);

            const fxClasses = [...span.classList].filter(c => c.startsWith('v-'));
            for (let c of fxClasses) span.classList.remove(c);
            span.classList.add(`v-${voiceId}`)

            if (settings.voiceStyles[voiceId] == undefined) {
                const idx = settings.styleIdx % settings.stylesList.length;
                settings.voiceStyles[voiceId] = idx;
                for (let entry in settings.stylesList[idx]) {
                    const selector = entry == 'element' ? `.v-${voiceId}` : `.v-${voiceId}${entry}`;
                    addGlobalCSS(selector, settings.stylesList[idx][entry]);
                }

                settings.styleIdx++;
            }

            if (!span.id) span.id = genID();


            //FIXME : update prosody data instead of deleting them
            //span.removeAttribute('data-dur');
            //span.removeAttribute('data-pitch');
            //span.classList.remove('adv-prosody');

        })
    })

    updateTTSChunks();

    sunEditor.core.history._lock = false;
    sunEditor.core.history.push();


}
VoiceEditor.getSelectedElements = () => {
    const sunEditor = VoiceEditor.sunEditor;
    const curRange = sunEditor.core.getRange();

    let containers = [];
    const startContainer = curRange.startContainer.nodeType == 3 ? curRange.startContainer.parentElement.closest('p') : curRange.startContainer.closest('p');
    const endContainer = curRange.endContainer.nodeType == 3 ? curRange.endContainer.parentElement.closest('p') : curRange.endContainer.closest('p');

    let curContainer = startContainer;
    containers.push(curContainer);

    let sanityCounter = 100;
    while (curContainer != endContainer && sanityCounter > 0) {
        sanityCounter--;

        curContainer = curContainer.nextSibling;
        containers.push(curContainer);
    }

    return containers;
}

VoiceEditor.setFx = (fxId) => {
    //if (!fxId) return;


    const sunEditor = VoiceEditor.sunEditor;
    //const curRange = sunEditor.core.getRange();



    let containers = VoiceEditor.getSelectedElements();


    containers.forEach(p => {
        p.querySelectorAll('.tts-chunk').forEach(span => {
            const fxClasses = [...span.classList].filter(c => c.startsWith('fx-'));
            for (let c of fxClasses) span.classList.remove(c);
            if (fxId) {
                span.setAttribute('fx', fxId);
                span.classList.add(`fx-common`);
                span.classList.add(`fx-${fxId}`);
            }
            else {
                span.removeAttribute('fx');
            }

        })
    })

    updateTTSChunks();

    sunEditor.core.history._lock = false;
    sunEditor.core.history.push();


}


VoiceEditor.getContent = () => {
    return VoiceEditor.sunEditor.getContents()
}
VoiceEditor.getSettings = () => {
    const _settings = JSON.parse(JSON.stringify(settings));
    delete _settings.voiceList;
    return JSON.stringify(_settings);
}
VoiceEditor.setContent = async (content) => {
    await VoiceEditor.ready();
    document.querySelector('.sun-editor-editable').innerHTML = content;
    //return VoiceEditor.sunEditor.setContents(content)
}

// VoiceEditor.on = (eventName, callback) => {
//     if (typeof callback != 'function') return;
//     editorEvents.voiceChange = callback;
// }
const attributesList = ['volume', 'rate', 'pitch', 'emphasis', 'emphasis-strong', 'emphasis-moderate', 'emphasis-reduced', 'say-as', 'sub', 'fx'];
const icons = {
    volume: '&#xea26',
    pitch: '&#xe993',
    rate: '&#xea20',
    emphasis: "&#xea0a",
    "say-as": '&#xe8af',
    sub: '&#xe607',
    fx: 'FX'
}
const ttsTagName = 'TTS';

VoiceEditor.getNodeSpeech = () => {
    const editor = VoiceEditor.sunEditor;
    const range = editor.core.getRange();

    let containerNode = null;
    const speechCfg = {};

    if (range.collapsed) {
        containerNode = range.startContainer;

    }
    else {
        let reqNewNode = false;
        let sameContainer = false;

        if (range.startContainer == range.endContainer) {
            sameContainer = true;
            containerNode = range.startContainer;
            if (containerNode.nodeType == 3) {//it's a text node, check the parent

                if (containerNode.parentElement.nodeName == ttsTagName && containerNode.length == (range.endOffset - range.startOffset)) {
                    //only needs update
                    containerNode = containerNode.parentElement;
                }
            }


        }
    }

    if (containerNode) {

        if (range.startContainer.nodeType == 3) containerNode = range.startContainer.parentElement.closest(ttsTagName);

        for (let attr of attributesList) {
            if (containerNode && containerNode.hasAttribute(attr)) speechCfg[attr] = containerNode.getAttribute(attr);
        }
    }

    return speechCfg;
}


function updatePluginsActiveStatus(element) {
    for (let pluginId of VoiceEditor.sunEditor.core.activePlugins) {
        const plugin = VoiceEditor.sunEditor.core.plugins[pluginId];
        plugin.active.call(VoiceEditor.sunEditor.core, element);
    }
}
/**
 * Triple click selection produce a range that goes to the next paragraph
 * this function tries to detect it and limit the range to the previous node
 */
function fixSelection() {
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    if (range.startContainer.nodeType == 3 && range.endContainer.nodeName == 'P' && range.startOffset == 0 && range.endOffset == 0) {
        range.selectNode(range.startContainer);
        sel.removeAllRanges();
        sel.addRange(range);

        if (range.startContainer == range.endContainer)
            updatePluginsActiveStatus(range.startContainer);

    }


    return range;
}

VoiceEditor.setNode = (settings = { volume, rate, pitch, emphasis, "say-as": undefined, sub, fx }) => {
    //fixSelection();
    const editor = VoiceEditor.sunEditor;



    const range = editor.core.getRange();
    let modified = false;

    if (range.collapsed) {



    }
    else {
        let reqNewNode = false;
        let sameContainer = false;
        let containerNode = null;
        if (range.startContainer == range.endContainer) {
            sameContainer = true;
            containerNode = range.startContainer;
            if (containerNode.nodeType == 3) {//it's a text node, check the parent

                if (containerNode.parentElement.nodeName == ttsTagName && containerNode.length == (range.endOffset - range.startOffset)) {
                    //only needs update
                    containerNode = containerNode.parentElement;
                }
                else {
                    reqNewNode = true;
                }
            }
            else {
                if (containerNode.nodeName != ttsTagName) {
                    console.warn('does it really require new node ?');
                    reqNewNode = true;
                }


            }


        }
        else {
            const frag = range.cloneContents()
            const fragNodes = [...frag.childNodes].filter(node => node.nodeType != 3 || node.nodeValue.trim() != '');

            if (fragNodes.length == 1) {
                range.extractContents();
                containerNode = fragNodes[0];
                range.insertNode(containerNode);
            }
            else {
                reqNewNode = true;
            }
        }


        if (!reqNewNode) {
            editor.core.history.push(true);
            if (containerNode.nodeName == ttsTagName) { //existing tts node selected, edit only
                for (let item in settings) {
                    const curVal = containerNode.getAttribute(item);
                    if (curVal) containerNode.classList.remove(`${item}-${curVal}`)

                    containerNode.setAttribute(item, settings[item]);
                    containerNode.classList.add(item);

                    if (settings[item].trim() && isNaN(parseInt(settings[item].trim()))) {
                        //console.log(`> adding class $ { item } -${ settings[item] } `)

                        containerNode.classList.add(`${item}-${settings[item]}`)
                    }
                }
            }

            modified = true;

        }
        else {
            const parentTTSNode = containerNode.nodeType == 3 ? containerNode.parentElement.closest('tts') : containerNode.closest('tts');

            //if (containerNode.nodeType == 3) containerNode = containerNode.parentElement.closest('tts');
            //check if we are clearing nodes only
            if (parentTTSNode && reqNewNode) {
                console.log('parentTTSNode', parentTTSNode);
                let found = false;
                for (let prop in settings) {
                    const attr = parentTTSNode.hasAttribute(prop);
                    if (attr) {
                        found = true;
                        break;
                    }
                }
                if (!found) reqNewNode = false;
                //if (Object.values(settings).join('').trim() == '') reqNewNode = false;
            }

            if (reqNewNode) {
                editor.core.history.push(true);
                const newNode = editor.util.createElement(ttsTagName);
                const id = genID();
                newNode.id = id;
                console.log('new Id=', id);
                newNode.classList.add('SSML');
                for (let item in settings) {
                    newNode.setAttribute(item, settings[item]);
                    if (settings[item]) newNode.classList.add(item);
                    if (settings[item].trim() && isNaN(parseInt(settings[item].trim()))) {
                        //console.log(`Adding class $ { item } -${ settings[item] } `)
                        newNode.classList.add(`${item}-${settings[item]}`)
                    }
                }
                newNode.append(range.extractContents());
                range.insertNode(newNode);


                const ttsNode = document.getElementById(id);
                ttsNode.removeAttribute('id');
                let pNode;
                if (sameContainer) {
                    pNode = ttsNode.parentElement.closest(ttsTagName);
                }
                else {
                    pNode = ttsNode;
                }
                if (pNode) {
                    pNode.childNodes.forEach(node => {
                        if (node.nodeType == 3) {
                            const tts = document.createElement(ttsTagName);  //create empty tts tag
                            tts.className = pNode.className;
                            node.after(tts);
                            tts.appendChild(node);      //replace text node with new tts tag
                            [...pNode.attributes].forEach(attr => {
                                if (attributesList.indexOf(attr.name) < 0) return;

                                tts.setAttribute(attr.name, attr.value)
                                tts.classList.add(attr.name);
                            }) //copy parent attributes

                        }
                        else {
                            if (node.nodeName == ttsTagName) //this is existing tts tag, we just need update attributes
                            {
                                if (!sameContainer) node.className = pNode.className;

                                [...pNode.attributes].forEach(attr => {
                                    if (!sameContainer || !node.hasAttribute(attr.name)) {
                                        if (attributesList.indexOf(attr.name) < 0) return;

                                        node.setAttribute(attr.name, attr.value)
                                        node.classList.add(attr.name);
                                    }

                                })

                            }
                        }

                    });



                    if (pNode.previousSibling && pNode.previousSibling.nodeName == ttsTagName && pNode.previousSibling.innerText.trim() == '') pNode.previousSibling.remove();
                    if (pNode.nextSibling && pNode.nextSibling.nodeName == ttsTagName && pNode.nextSibling.innerText.trim() == '') pNode.nextSibling.remove();

                    const paragraphs = [...pNode.querySelectorAll('p')]; //in case of formatting applied to whole paragraphs
                    if (paragraphs.length) {
                        if (pNode.previousSibling && pNode.previousSibling.nodeName == 'P' && pNode.previousSibling.innerText.trim() == '') pNode.previousSibling.remove();
                        if (pNode.nextSibling && pNode.nextSibling.nodeName == 'P' && pNode.nextSibling.innerText.trim() == '') pNode.nextSibling.remove();

                        paragraphs.forEach(p => {
                            p.querySelectorAll('span').forEach(span => {
                                if (span.innerText.trim() == '') {
                                    span.remove();
                                }
                                else {
                                    const clone = pNode.cloneNode();
                                    span.childNodes.forEach(child => clone.appendChild(child));
                                    span.append(clone);

                                }
                            })
                        })
                    }
                    pNode.outerHTML = pNode.innerHTML; //unwrap parent to keep children tts nodes only
                }

                modified = true;


            }

        }


        if (modified) {

            const ttsTags = [...document.querySelectorAll('.sun-editor-editable p>span tts')];

            ttsTags.reduce((cumul, next) => {
                if (cumul.nextSibling == next) {
                    let match = true;
                    for (let attr of attributesList) {
                        if (cumul.getAttribute(attr) != next.getAttribute(attr)) {
                            match = false;
                            break;
                        }
                    }
                    if (match) {
                        next.childNodes.forEach(child => cumul.appendChild(child));
                        next.remove();
                        return cumul;
                    }



                }
                return next;
            });


            //TODO : optimize this loop
            document.querySelectorAll('.sun-editor-editable tts').forEach(node => {
                if (node.innerText.trim() == '') {
                    node.remove();
                    return;
                }

                let remainingAttributes = [];
                [...node.attributes].forEach(attr => {
                    if (attributesList.indexOf(attr.name) < 0) return;

                    if (attr.value.trim() == '') {
                        node.removeAttribute(attr.name);
                        node.classList.remove(attr.name);
                    }
                    else {
                        remainingAttributes.push(attr);
                        node.classList.add(attr.name);
                    }
                })



                if (remainingAttributes.length == 0) //no more attributes ==> unwrap node
                {
                    node.outerHTML = node.innerHTML;
                }
                else {
                    //set data label

                    const dummy = $('<div/>');

                    let label = '';

                    attributesList.forEach(a => {
                        const attr = node.attributes[a];
                        if (!attr) return;

                        const icon = dummy.html(icons[attr.name]).text();
                        if (icons[attr.name]) label += `${icon} [${attr.value}]`
                    })
                    node.setAttribute('data-label', label);
                }
            })



            //TODO : merge successive tts tags




            //editor.core.history.push();




        }


    }
}

VoiceEditor.getCurChunk = function () {
    const range = VoiceEditor.sunEditor.core.getRange();
    const start = range.startContainer.parentElement.closest('.tts-chunk');
    const end = range.endContainer.parentElement.closest('.tts-chunk');

    if (start != end) return [start, end];
    return [start];
}

VoiceEditor.generate = async function (chunk) {
    let error = null;
    const chunks = chunk ? prepareTTSChunk(chunk) : prepareTTSRequest();
    if (!chunks || chunks.length <= 0) return;
    const reqBody = { token: AppSettings.userSettings.token, chunks };
    const result = await api.post(AppSettings.appSettings.ttsEndpoint, reqBody).catch(error => {
        throw new Error(error.message);
    });

    return result;
}

VoiceEditor.calcCredits = function (chunk) {

    let credits = 0;
    const chunks = chunk ? prepareTTSChunk(chunk) : prepareTTSRequest(false);
    if (!chunks || chunks.length <= 0) return credits;
    console.log('calc ', chunks);

    let lastNodeId = null;
    let curCredit = 0;
    for (let node of chunks) {
        const credit = getCredEstimate(node);
        if (lastNodeId != node.id) {
            lastNodeId = node.id;
            curCredit = credit;
        }
        else {
            curCredit += credit;
        }

        if (node.voice != 'FX') { //ignore FX pseudo nodes
            const ttsCunk = document.getElementById(node.id);
            if (ttsCunk)
                ttsCunk.parentElement.setAttribute('data-credit', '-' + curCredit + ' Credits');
        }

        credits += credit;
    }

    return credits;
}

function getCredEstimate(node) {
    const nodes = node.data ? (node.data.elements || []) : (node.elements || []);
    const ttsAttributes = ['emphasis', 'say-as', 'pitch', 'rate', 'volume'];

    let txt = node.text ? node.text.length : 0;
    for (let child of nodes) {
        switch (child.name) {
            case 'tts':
                if (child.attributes) {
                    for (let attr of ttsAttributes) {
                        if (child.attributes[attr]) {
                            txt += ((Math.max(attr.length, 7) + 4) * 2) + 10;
                            txt += child.attributes[attr].length;
                        }
                    }

                }
                break;

            case 'breath':
                txt += 10;
                break;

            case 'break':
                txt += 10;
                break;
        }



        if (child.text) txt += child.text.length;
        else txt += getCredEstimate(child);
    }


    return txt;
}

function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

function encodeContent(content, settingsStr) {
    return xml2json(`<talky settings="${utf8_to_b64(settingsStr)}"> ${content} </talky>`);
}
function decodeContent(content) {
    const obj = JSON.parse(content);
    let settings;
    if (!obj) return { content: '', settings: null };
    if (obj.elements && obj.elements[0] && obj.elements[0].attributes && obj.elements[0].attributes.settings) {
        try {
            settings = JSON.parse(b64_to_utf8(obj.elements[0].attributes.settings));
        } catch (ex) { };
    }

    const htmlContent = json2xml(obj).replace(/<\/?talky[^>]*>/gmi, '');

    return { content: htmlContent, settings };
}

VoiceEditor.undo = function () {
    VoiceEditor.sunEditor.core.history.undo();
}
VoiceEditor.redo = function () {
    VoiceEditor.sunEditor.core.history.redo();
}


VoiceEditor.saveFile = async function () {
    const content = VoiceEditor.getContent();
    const settingsStr = VoiceEditor.getSettings();
    let filePath = null;
    if (!VoiceEditor.file.path) filePath = await RPC.menu.file.save(encodeContent(content, settingsStr))
    else filePath = await RPC.menu.file.save(encodeContent(content, settingsStr), VoiceEditor.file.path);

    if (!filePath) return false;
    VoiceEditor.file.path = filePath;
    VoiceEditor.file.content = content;
    VoiceEditor.file.changed = false;

    titlebar.setTitle(path.basename(VoiceEditor.file.path));
    titlebar.setChanged(false);

    return true;
}

VoiceEditor.saveFileAs = async function () {
    const content = VoiceEditor.getContent();
    const settingsStr = VoiceEditor.getSettings();
    const filePath = await RPC.menu.file.save(encodeContent(content, settingsStr));
    if (!filePath) return false;

    VoiceEditor.file.path = filePath;
    VoiceEditor.file.content = content;
    VoiceEditor.file.changed = false;

    titlebar.setTitle(path.basename(VoiceEditor.file.path));
    titlebar.setChanged(false);
    return true;
}

VoiceEditor.openFile = async function () {
    {
        const newWindow = VoiceEditor.file.path || document.querySelector('.sun-editor-editable').innerText.trim() !== '';
        const file = await RPC.menu.file.open(newWindow);
    }
}

VoiceEditor.loadVoiceStyles = function () {

    for (let voiceId in settings.voiceStyles) {
        const idx = settings.voiceStyles[voiceId];

        for (let entry in settings.stylesList[idx]) {
            const selector = entry == 'element' ? `.v-${voiceId}` : `.v-${voiceId}${entry}`;
            addGlobalCSS(selector, settings.stylesList[idx][entry]);
        }

        //addGlobalCSS(`.v-${voiceId}`, settings.stylesList[idx].element);
    }
}

VoiceEditor.loadData = async function (data) {
    await VoiceEditor.ready();

    if (data.path && data.content) {
        VoiceEditor.file.path = data.path;
        const decoded = decodeContent(data.content);
        if (decoded.settings) {
            settings.styleIdx = decoded.settings.styleIdx;
            settings.voiceStyles = decoded.settings.voiceStyles;
            settings.stylesList = decoded.settings.stylesList;
            settings.stylesList.forEach(item => {
                if (!item.element) {
                    const clone = JSON.parse(JSON.stringify(item));
                    for (let i in item) delete item[i];
                    item.element = clone;
                }
            })
            VoiceEditor.loadVoiceStyles();
        }
        VoiceEditor.file.content = decoded.content;
        VoiceEditor.file.changed = false;
        VoiceEditor.file.loadedTime = Date.now();
        VoiceEditor.setContent(VoiceEditor.file.content);

        titlebar.setTitle(path.basename(VoiceEditor.file.path));
        titlebar.setChanged(false);
    }
    VoiceEditor.sunEditor.core.history.reset(false);
    VoiceEditor.sunEditor.core.history.stack = [];
}




let mouseDownEvt;
let lastPageX = null;
VoiceEditor.snapSelection = function (snap) {
    if (!mouseDownEvt) {
        document.querySelector('.sun-editor .sun-editor-editable').addEventListener('mousedown', (e: any) => {
            lastPageX = e.pageX;
        });
        mouseDownEvt = true;
    }

    AppSettings.userSettings.editor.snap = snap;
    AppSettings.saveAppSettings();
}

function snapSelectionToWord(e) {
    if (!AppSettings.userSettings.editor.snap || lastPageX === null || Math.abs(e.pageX - lastPageX) < 3) {
        fixSelection();
        return;
    }
    var sel;

    // Check for existence of window.getSelection() and that it has a
    // modify() method. IE 9 has both selection APIs but no modify() method.
    if (window.getSelection && (sel = window.getSelection()).modify) {
        sel = window.getSelection();
        if (!sel.isCollapsed) {

            // Detect if selection is backwards
            var range = document.createRange();
            range.setStart(sel.anchorNode, sel.anchorOffset);
            range.setEnd(sel.focusNode, sel.focusOffset);
            var backwards = range.collapsed;
            range.detach();

            // modify() works on the focus of the selection
            var endNode = sel.focusNode, endOffset = sel.focusOffset;
            sel.collapse(sel.anchorNode, sel.anchorOffset);

            var direction = [];
            if (backwards) {
                direction = ['backward', 'forward'];
            } else {
                direction = ['forward', 'backward'];
            }

            sel.modify("move", direction[0], "character");
            sel.modify("move", direction[1], "word");
            sel.extend(endNode, endOffset);
            sel.modify("extend", direction[1], "character");
            sel.modify("extend", direction[0], "word");
        }
    } else if ((sel = document.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        if (textRange.text) {
            textRange.expand("word");
            // Move the end back to not include the word's trailing space(s),
            // if necessary
            while (/\s$/.test(textRange.text)) {
                textRange.moveEnd("character", -1);
            }
            textRange.select();
        }
    }
    fixSelection();
}


VoiceEditor.setEndPoint = function (defaultServerURL) {
    AppSettings.appSettings.serverUrl = defaultServerURL;
    AppSettings.appSettings.ttsEndpoint = `${defaultServerURL}/tts`;
    AppSettings.appSettings.actorsEndpoint = `${defaultServerURL}/actors`;
    AppSettings.appSettings.fxEndpoint = `${defaultServerURL}/fx`;
    AppSettings.appSettings.fxListEndpoint = `${defaultServerURL}/fxList`;
    AppSettings.appSettings.filesEndpoint = `${defaultServerURL}/audio/`;

    AppSettings.saveAppSettings();
}

VoiceEditor.appSettings = AppSettings.appSettings;
export default VoiceEditor;