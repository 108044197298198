import VoiceEditor from "../editor";

const commandName = 'adv-prosody';
let curChunk: HTMLElement;
export default {
    name: commandName,
    display: 'command',

    title: 'Prosody',
    // This icon uses Font Awesome
    innerHTML: '<i class="mif-equalizer-v"></i>',
    buttonClass: 'ui button tbtn-adv-prosody',
    add: function (core: any, targetElement: HTMLElement) {
        const context = core.context;
        context[commandName] = {
            targetButton: targetElement
        };


        const span = document.createElement('span')
        span.className = 'txt';
        span.innerText = `[Prosody]`;
        targetElement.appendChild(span);

    },
    active: function (element: HTMLElement) {

        return false;
    },
    action: function () {
        const chunkId = $('#generateChunkBtn').attr('rel');


        curChunk = document.getElementById(chunkId);
        const durData = curChunk.getAttribute('data-dur');
        const pitchData = curChunk.getAttribute('data-pitch');

        $('#CSContainer').addClass('loading');
        $('.prosody-editor-dlg .loader').addClass('active');

        Metro.dialog.open('#prosody-editor');
        VoiceEditor.generate(curChunk)
            .then(async (result) => {
                const partId = result.parts[0].id;
                await VoiceEditor.prosodyEditor.load(partId, pitchData, durData);
                $('.prosody-editor-dlg .loader').removeClass('active');
                $('#CSContainer').removeClass('loading');
            })
            .catch(e => {
                Metro.toast.create("An error occured, please try again", null, 5000, "alert");

            });

    }
}


window.prosodyEditorActions = [
    {
        caption: "Preview",
        cls: "btn-play button success outline tooltip float-left",
        onclick: async () => {
            VoiceEditor.prosodyEditor.wavesurfer.stop();
            if (!VoiceEditor.prosodyEditor._changed) {
                VoiceEditor.prosodyEditor.wavesurfer.play();
                return;
            }

            VoiceEditor.prosodyEditor.needPlay = true;
            const pitchDurData = await VoiceEditor.prosodyEditor.updateSound();
            VoiceEditor.prosodyEditor.postData(pitchDurData);

        }
    },
    {
        caption: "Reset",
        cls: "btn-reset button alert outline tooltip float-left",
        onclick: () => {
            VoiceEditor.prosodyEditor.reset();
        }
    },
    {
        caption: "Cancel",
        cls: "btn-cancel js-dialog-close button warning outline tooltip",
        onclick: () => {
        }
    },
    {
        caption: "Save & Close",
        cls: "btn-ok js-dialog-close button success outline tooltip",
        onclick: () => {
            const pitchData = VoiceEditor.prosodyEditor.getPitchData();
            const durData = VoiceEditor.prosodyEditor.getDurData();
            curChunk.setAttribute('data-pitch', pitchData);
            curChunk.setAttribute('data-dur', durData);
            curChunk.classList.add('adv-prosody');

            VoiceEditor.prosodyEditor.destroy();
            $('.prosody-editor-dlg .loader').addClass('active');
        }
    }

];