//const $ = require('jquery');
declare var $: any;
const isBrowser = (typeof process == 'undefined' || process['browser'])




function updateMaxStatus() {
    RPC.window.isMaximized().then((maximized: boolean) => {
        if (maximized)
            $('#btnMax i').removeClass('fa-window-maximize').addClass('fa-window-restore')
        else
            $('#btnMax i').removeClass('fa-window-restore').addClass('fa-window-maximize')
    })
}


export function init({ onMinimize, onMaximize, onClose }) {
    console.log('init winctrl');
    if (isBrowser) {
        const winControls = document.getElementById('winControls');
        winControls.style.display = 'none';
        return;
    }

    updateMaxStatus();

    $('#btnMin').on('click', RPC.window.minimize);
    $('#btnMax').on('click', () => {
        RPC.window.maximize();
        updateMaxStatus();
    });
    $('#btnClose').on('click', async () => {
        if (typeof onClose == 'function') {
            const canClose = await onClose();
            if (canClose) $('#overlay').fadeIn('fast', RPC.window.close);
        }
    });
}