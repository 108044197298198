


export function applyOverrides(sunEditor) {

    if (!sunEditor || !sunEditor.util || !sunEditor.util.isSameAttributes) return;
    const _d = sunEditor.core._d;
    const util = sunEditor.core.util;
    const options = sunEditor.core.options;

    const originalPush = sunEditor.core.history.push;
    sunEditor.core.history.push = function (t) {
        //console.log('History push', sunEditor.core.history._lock);
        if (sunEditor.core.history._lock) return;
        else return originalPush(t);
    }


    const _cleanLine = function (node, requireFormat) {
        const defaultTag = options.defaultTag;
        // element
        if (node.nodeType === 1) {
            if (util._disallowedTags(node)) return '';
            if (!requireFormat || (util.isFormatElement(node) || util.isRangeFormatElement(node) || util.isComponent(node) || util.isMedia(node) || (util.isAnchor(node) && util.isMedia(node.firstElementChild)))) {
                return node.outerHTML;
            } else {
                node.removeAttribute('style');
                return node.outerHTML;
            }
        }
        // text
        if (node.nodeType === 3) {
            if (!requireFormat) return node.textContent;
            const textArray = node.textContent.split(/\n/g);
            let html = '';
            for (let i = 0, tLen = textArray.length, text; i < tLen; i++) {
                text = textArray[i].trim();
                if (text.length > 0) html += text;
            }
            return html;
        }
        // comments
        if (node.nodeType === 8 && sunEditor.core._allowHTMLComments) {
            return '<!--' + node.textContent.trim() + '-->';
        }

        return '';
    }
    sunEditor.util.isTextStyleElement = function (element) {
        return element && element.nodeType !== 3 && /^(emphasis|say-as|prosody|strong|span|font|b|var|i|em|u|ins|s|strike|del|sub|sup|mark|a|label|code)$/i.test(element.nodeName);
    }
    sunEditor.core.cleanHTML = function (html, whitelist) {
        sunEditor.core.history._lock = true;
        console.log('cleaning HTML');
        const range = this.getRange();

        html = this._deleteDisallowedTags(html).replace(/(<[a-zA-Z0-9]+)[^>]*(?=>)/g, this._cleanTags.bind(this, false));
        const selfCopy = html.indexOf('SSML') >= 0;
        if (selfCopy) html = html.replace(/<span> <\/span\s*>/g, ' ');

        const dom = _d.createRange().createContextualFragment(html);
        try {
            util._consistencyCheckOfHTML(dom, this._htmlCheckWhitelistRegExp, options.allowStyles);
        } catch (error) {
            this.history._lock = false;
            console.warn('[SUNEDITOR.cleanHTML.consistencyCheck.fail] ' + error);
        }

        if (this.managedTagsInfo && this.managedTagsInfo.query) {
            const textCompList = dom.querySelectorAll(this.managedTagsInfo.query);
            for (let i = 0, len = textCompList.length, initMethod, classList; i < len; i++) {
                classList = [].slice.call(textCompList[i].classList);
                for (let c = 0, cLen = classList.length; c < cLen; c++) {
                    initMethod = this.managedTagsInfo.map[classList[c]];
                    if (initMethod) {
                        initMethod(textCompList[i]);
                        break;
                    }
                }
            }
        }

        //Custom clean for Talky studio
        dom.querySelectorAll('h1,h2,h3,h4,h5').forEach(element => {
            element.removeAttribute('style');
            element.removeAttribute('class');
            const attrNames = element.getAttributeNames() || [];
            attrNames.forEach(attr => element.removeAttribute(attr))


            const p = document.createElement('p');

            const span = document.createElement('span');
            span.className = 'tts-chunk';
            span.innerText = element.innerText;
            p.appendChild(span);
            element.innerHTML = p.outerHTML;
        })
        dom.querySelectorAll('li').forEach(element => {
            const p = document.createElement('p');
            const span = document.createElement('span');
            span.className = 'tts-chunk';
            span.innerText = element.innerText;
            p.appendChild(span);
            element.parentElement.insertBefore(p, element);
            element.remove();
        })


        dom.querySelectorAll('span span').forEach(span => span.outerHTML = span.innerHTML);
        dom.querySelectorAll('span').forEach(span => span.removeAttribute('style'));
        dom.querySelectorAll('p').forEach(p => {
            p.childNodes.forEach(child => {
                if (child.nodeType === Node.TEXT_NODE) {
                    const span = document.createElement('span');
                    span.innerText = child.textContent;
                    span.className = 'tts-chunk';


                    p.insertBefore(span, child);
                    child.remove();
                }
            })
        });
        //[...dom.querySelectorAll('span')].filter(s => !s.classList.contains('tts-chunk')).forEach(s => s.classList.add('tts-chunk'));
        // dom.querySelectorAll('p').forEach(p => mergeSpans(p));

        dom.querySelectorAll('p').forEach(p => {
            const attrNames = p.getAttributeNames() || [];
            attrNames.forEach(attr => p.removeAttribute(attr))

            if (!p.innerText.trim()) p.remove();
        })

        //dom.querySelectorAll('span span').forEach(span => span.outerHTML = span.innerHTML);
        const hasParagraph = dom.querySelector('p');


        const addSpanProps = (range.endContainer.nodeType == 3 && range.endOffset == range.endContainer.textContent.length) ||
            (!range.collapsed && !range.endContainer.nodeName == 'DIV') ||
            (range.startContainer == range.endContainer && range.startContainer.nodeType !== 3)

        if (hasParagraph || addSpanProps) {
            console.log('Add Span props')
            dom.querySelectorAll('span').forEach(s => {
                const voice = VoiceEditor.getVoice();
                const label = voice.substr(0, voice.indexOf('-'));
                s.className = 'tts-chunk';
                s.classList.add(`v-${voice}`);
                s.setAttribute('voice', voice);
                s.setAttribute('data-label', label);
            })
        }
        //===========================================

        const domTree = dom.childNodes;
        let cleanHTML = '';
        let requireFormat = false;

        for (let i = 0, len = domTree.length, t; i < len; i++) {
            t = domTree[i];
            if (t.nodeType === 1 && !util.isTextStyleElement(t) && !util.isBreak(t) && !util._disallowedTags(t)) {
                requireFormat = true;
                break;
            }
        }


        for (let i = 0, len = domTree.length; i < len; i++) {
            cleanHTML += selfCopy ? _cleanLine(domTree[i], requireFormat) : this._makeLine(domTree[i], requireFormat);
        }

        // if (!selfCopy) {
        //     cleanHTML = html.replace(/<span> <\/span >/g, ' ');
        // }

        cleanHTML = util.htmlRemoveWhiteSpace(cleanHTML);
        cleanHTML = this._tagConvertor(!cleanHTML ? html : !whitelist ? cleanHTML : cleanHTML.replace(typeof whitelist === 'string' ? util.createTagsWhitelist(whitelist) : whitelist, ''));


        /*Talky Studio post process past*/

        cleanHTML = cleanHTML.replace(/<\/?(ul|h1|h2|h3|h4|h5)[^>]*>/gmi, '')
        if (!hasParagraph && range.commonAncestorContainer.nodeType == 3) {
            cleanHTML = cleanHTML.replace(/<\/?(span)[^>]*>/gmi, '')
        }
        // cleanHTML = cleanHTML.replace(/<span/gmi, '<p><span');
        // cleanHTML = cleanHTML.replace(/<\/span>/gmi, '</span></p>');

        setTimeout(() => {
            sunEditor.core.history._lock = false;
            sunEditor.core.history.push();
        }, 100);

        return cleanHTML;
    }


    sunEditor.util.isSameAttributes = function (a, b) {
        if (a.nodeType === 3 && b.nodeType === 3) return true;
        if (a.nodeType === 3 || b.nodeType === 3) return false;
        if (a.id != b.id) return false;

        const equalClass = [...a.classList].sort().toString().toLowerCase() == [...b.classList].sort().toString().toLowerCase();

        const equalAttr =
            [...a.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()
            ==
            [...b.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()

        return equalClass && equalAttr;
        /*
                const style_a = a.style;
                const style_b = b.style;
                let compStyle = 0;
        
                for (let i = 0, len = style_a.length; i < len; i++) {
                    if (style_a[style_a[i]] === style_b[style_a[i]]) compStyle++;
                }
        
                const class_a = a.classList;
                const class_b = b.classList;
                const reg = this._w.RegExp;
                let compClass = 0;
        
                for (let i = 0, len = class_a.length; i < len; i++) {
                    if (reg('(\s|^)' + class_a[i] + '(\s|$)').test(class_b.value)) compClass++;
                }
        
                return (compStyle === style_b.length && compStyle === style_a.length) && (compClass === class_b.length && compClass === class_a.length);
        */
    }


    sunEditor.util.mergeSameTags = function (element, nodePathArray, onlyText) {
        const inst = this;
        const nodePathLen = nodePathArray ? nodePathArray.length : 0;
        let offsets = null;

        if (nodePathLen) {
            offsets = this._w.Array.apply(null, new this._w.Array(nodePathLen)).map(this._w.Number.prototype.valueOf, 0);
        }

        (function recursionFunc(current, depth, depthIndex) {
            const children = current.childNodes;

            for (let i = 0, len = children.length, child, next; i < len; i++) {
                child = children[i];
                next = children[i + 1];
                if (!child) break;

                if ((onlyText && inst._isIgnoreNodeChange(child)) || (!onlyText && (inst.isTable(child) || inst.isListCell(child) || (inst.isFormatElement(child) && !inst.isFreeFormatElement(child))))) {
                    if (inst.isTable(child) || inst.isListCell(child)) {
                        recursionFunc(child, depth + 1, i);
                    }
                    continue;
                }

                if (len === 1 && current.nodeName === child.nodeName && current.parentNode) {

                    // update nodePath
                    if (nodePathLen) {
                        let path, c, p, cDepth, spliceDepth;
                        for (let n = 0; n < nodePathLen; n++) {
                            path = nodePathArray[n];
                            if (path && path[depth] === i) {
                                c = child, p = current, cDepth = depth, spliceDepth = true;
                                while (cDepth >= 0) {
                                    if (inst.getArrayIndex(p.childNodes, c) !== path[cDepth]) {
                                        spliceDepth = false;
                                        break;
                                    }
                                    c = child.parentNode;
                                    p = c.parentNode;
                                    cDepth--;
                                }
                                if (spliceDepth) {
                                    path.splice(depth, 1);
                                    path[depth] = i;
                                }
                            }
                        }
                    }

                    // merge tag
                    inst.copyTagAttributes(child, current);
                    current.parentNode.insertBefore(child, current);
                    inst.removeItem(current);
                }
                if (!next) {
                    if (child.nodeType === 1) recursionFunc(child, depth + 1, i);
                    break;
                }

                if (child.nodeName === next.nodeName && inst.isSameAttributes(child, next) && child.href === next.href) {

                    const childs = child.childNodes;
                    let childLength = 0;
                    for (let n = 0, nLen = childs.length; n < nLen; n++) {
                        if (childs[n].textContent.length > 0) childLength++;
                    }

                    const l = child.lastChild;
                    const r = next.firstChild;
                    let addOffset = 0;
                    if (l && r) {
                        const textOffset = l.nodeType === 3 && r.nodeType === 3;
                        addOffset = l.textContent.length;
                        let tempL = l.previousSibling;
                        while (tempL && tempL.nodeType === 3) {
                            addOffset += tempL.textContent.length;
                            tempL = tempL.previousSibling;
                        }

                        if (childLength > 0 && l.nodeType === 3 && r.nodeType === 3 && (l.textContent.length > 0 || r.textContent.length > 0)) childLength--;

                        if (nodePathLen) {
                            let path = null;
                            for (let n = 0; n < nodePathLen; n++) {
                                path = nodePathArray[n];
                                if (path && path[depth] > i) {
                                    if (depth > 0 && path[depth - 1] !== depthIndex) continue;

                                    path[depth] -= 1;
                                    if (path[depth + 1] >= 0 && path[depth] === i) {
                                        path[depth + 1] += childLength;
                                        if (textOffset) {
                                            if (l && l.nodeType === 3 && r && r.nodeType === 3) {
                                                offsets[n] += addOffset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (child.nodeType === 3) {
                        addOffset = child.textContent.length;
                        child.textContent += next.textContent;
                        if (nodePathLen) {
                            let path = null;
                            for (let n = 0; n < nodePathLen; n++) {
                                path = nodePathArray[n];
                                if (path && path[depth] > i) {
                                    if (depth > 0 && path[depth - 1] !== depthIndex) continue;

                                    path[depth] -= 1;
                                    if (path[depth + 1] >= 0 && path[depth] === i) {
                                        path[depth + 1] += childLength;
                                        offsets[n] += addOffset;
                                    }
                                }
                            }
                        }
                    } else {
                        child.innerHTML += next.innerHTML;
                    }

                    inst.removeItem(next);
                    i--;
                } else if (child.nodeType === 1) {
                    recursionFunc(child, depth + 1, i);
                }

                // if (child.classList.contains('tts-chunk')) {
                //     child.classList.remove('generated');
                //     child.id = '';
                // }
                // if (next.classList.contains('tts-chunk')) {
                //     next.classList.remove('generated');
                //     next.id = '';
                // }
            }
        })(element, 0, 0);

        return offsets;
    }
}

function mergeSpans(para) {

    para.removeAttribute('style'); //remove styles
    var curSpan = null;

    //first merge text nodes to spans
    [...para.childNodes].filter(c => c.nodeType === 3).forEach((c) => {
        let html = '';
        if (c.previousSibling) html += c.previousSibling.nodeType === 3 ? c.previousSibling.textContent : c.previousSibling.innerHTML;
        html += c.textContent;
        if (c.nextSibling) html += c.nextSibling.nodeType === 3 ? c.nextSibling.textContent : c.nextSibling.innerHTML;


        var newhtml = html;


        if (c.previousSibling && c.previousSibling.nodeType === 1) {
            c.previousSibling.outerHTML = newhtml;
            c.remove();
            if (c.nextSibling) c.nextSibling.remove;
            return;
        }
        if (c.nextSibling && c.nextSibling.nodeType === 1) {
            c.nextSibling.outerHTML = newhtml;
            c.remove();
            if (c.previousSibling) c.previousSibling.remove;
            return;
        }
    });


    [...para.children].forEach((child) => {
        if (child.tagName == 'SPAN' && child.classList.contains('tts-chunk')) {
            [...child.querySelectorAll('span[voice]')] //find sub voice spans and marge them with parent
                .forEach(span => {
                    span.outerHTML = span.innerHTML;
                });
        }

        if (!curSpan) {
            if (child.tagName == 'SPAN') curSpan = child;

            else { //first child not a span, check if it's an SSML tag with span child
                console.log('c1');
                if (child.childNodes.length == 1 && child.firstChild.nodeType === 1 && child.firstChild.tagName == 'SPAN') {
                    const childSpan = child.firstChild;
                    const cloneSpan = document.createElement('span');
                    [...childSpan.attributes].forEach(attr => cloneSpan.setAttribute(attr.name, attr.value));

                    childSpan.outerHTML = childSpan.innerHTML; //unwrap child span;
                    cloneSpan.innerHTML = child.outerHTML; //wrap the SSML tag with the cloned span

                    child.parentElement.insertBefore(cloneSpan, child);

                    child.remove(); //we just placed a cloned span wraping the content of this child, we can remove it now

                    curSpan = cloneSpan;
                }
            }

            return;
        }
        if (child.tagName != 'SPAN') {
            [...child.childNodes].forEach((node) => {
                if (node.nodeType === 3) //handle text node
                {
                    curSpan.appendChild(node);
                    return;
                }

                if (node.tagName == 'SPAN') {
                    console.log('c2')
                    const parent = child.parentElement;
                    const childcopy = document.createElement(child.tagName);
                    [...child.attributes].forEach(attr => childcopy.setAttribute(attr.name, attr.value)); //copy attributes

                    const span = node;
                    if (span.id != curSpan.id) {
                        childcopy.innerHTML = span.innerHTML;
                        span.innerHTML = '';
                        span.appendChild(childcopy);
                        parent.insertBefore(span, child);

                        curSpan = span;
                        return
                    }
                    else {
                        childcopy.innerHTML = span.innerHTML;

                        curSpan.appendChild(childcopy);
                    }


                }
            })
            child.remove();
            return;
        }

        if (child.tagName == 'SPAN') {

            const equalClass = [...curSpan.classList].sort().toString().toLowerCase() == [...child.classList].sort().toString().toLowerCase();

            const equalAttr =
                [...curSpan.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()
                ==
                [...child.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()


            if (curSpan.id == child.id && equalClass && equalAttr) { //if same span merge them
                //console.log('same ID', curSpan.id, curSpan, child.firstChild);
                [...child.childNodes].forEach(node => curSpan.appendChild(node));
                child.remove()

                sunEditor.util.mergeSameTags(curSpan, [[1, 0][1, 0]], true) //then merge their children
            }
            else {
                //console.log('different ID');
                curSpan = child;
            }


            return;
        }


    })
}


