import { updateSSML } from '../utils/ttstools'
const tagName = 'prosody';
const ssmlattributes = ['rate', 'pitch', 'volume'];
const attributes = [...ssmlattributes, 'data-label'];


const sliders = {
    rate: {
        instance: null,
        //labels: { 50: "x-slow", 75: "slow", 100: "default", 125: "fast", 150: "x-fast" }
        labels: { 0: "default" }
    },
    pitch: {
        instance: null,
        labels: { 0: "default" }
    },
    volume: {
        instance: null,
        labels: { 100: "default" }
    }
}





let editor;
class Prosody {
    public control;
    public title;
    constructor({ name, title, icon, control, cssClass }) {


        this.control = control;
        this.title = title;
        this.name = name;
        this.icon = icon;
        this.cssClass = cssClass;

        this.optionsWindowReady = false;
        const commandName = this.name || `ssml-${tagName}`;
        const instance = this;
        this.editor = null;

        const plugin = {
            name: this.name || commandName,
            display: 'command',
            title: this.title || 'Prosody',
            control: this.control,
            innerHTML: `<i class="${this.icon || 'fa-wave-square'}"></i>`,
            buttonClass: `button ${this.cssClass || 'tbtn-prosody'}`,
        }

        this.plugin = plugin;

        plugin.add = function (core, targetElement) {

            const context = core.context;
            const span = document.createElement('span')
            instance.context = context[commandName] = {
                targetButton: targetElement,
                targetText: span,
                instance: this//save object instance to avoid confusion with suneditor instance
            };


            span.className = 'txt';
            span.innerText = `[${instance.control}]`;
            targetElement.appendChild(span);
        }

        plugin.active = function (element) {
            instance.editor = this; //this object is bound to suneditor


            const nodeSpeech = VoiceEditor.getNodeSpeech();

            if (!element) {
                instance.editor.util.removeClass(instance.context.targetButton, 'active');
                instance.editor.util.changeTxt(instance.context.targetText, `[${instance.control}]`);
            } else if (nodeSpeech && nodeSpeech[instance.control]) {
                instance.editor.util.addClass(instance.context.targetButton, 'active');
                instance.editor.util.changeTxt(instance.context.targetText, `${instance.control}:${nodeSpeech[instance.control]}`);
                return true;
            }

            return false;
        }

        plugin.action = function () {
            instance.editor = this; //this object is bound to suneditor
            editor = instance.editor;


            let curRange = instance.editor.getRange();
            if (curRange.collapsed) {
                if (!curRange.startContainer.parentElement.innerText.trim()) return;
                $(curRange.startContainer.parentElement).selectText();

                curRange = instance.editor.getRange();
            }


            const brect = curRange.getBoundingClientRect();

            instance.prepareOptionsWindow();
            Metro.dialog.open('#prosody-modal');

            const dlgWidth = $('#prosody-modal').width();
            $('#prosody-modal').offset({ top: brect.y + 30, left: Math.min(brect.x, window.innerWidth - dlgWidth) });

            for (let s in sliders) {
                const slider = sliders[s].instance;
                const labels = sliders[s].labels;

                const val =
                    $(curRange.startContainer).attr(s) ||
                    $(curRange.startContainer.parentElement).attr(s) ||
                    $(curRange.endContainer).attr(s) ||
                    $(curRange.endContainer.parentElement).attr(s) ||
                    Object.keys(labels).find(k => labels[k] == 'default');

                const newVal = Object.keys(labels).find(k => labels[k] == val) || parseInt(val)
                //const newVal = parseInt(val)

                slider.val(newVal);
            }
        }

    }

    selectionHasTag(tag) {
        if (!this.editor) return;
        const range = this.editor.getRange();

        const uTag = tag.toUpperCase();
        let found = (range.startContainer.nodeName == uTag || range.startContainer.parentElement.nodeName == uTag ||
            range.endContainer.nodeName == uTag || range.endContainer.parentElement.nodeName == uTag);

        if (found) return true;


        const clonedSelection = range.cloneContents();

        const tagsList = [...clonedSelection.children].filter(n => n.nodeName == uTag);

        return tagsList.length > 0;

    }





    prepareOptionsWindow() {


        $('.prosody-slider').each((k, v) => {
            if ($(v).find(`#prosody-${this.control}-slider`).length > 0) {
                $(v).show();
                $(v).data('active', true);
                $(v).attr('active', 'true');
            }
            else {
                $(v).hide();
                $(v).data('active', false);
                $(v).attr('active', '');
            }
        });


        $('#prosody-modal .dialog-title').html(`${this.title} settings`);

        //$('#prosody-modal button.btn-ok').html('<span class="mif-checkmark icon fg-green"></span><span class="caption">Ok</span>')
        $('#prosody-modal button.btn-cancel').html(`<span class="mif-cross icon fg-orange"></span><span class="tooltiptext">Cancel changes</span>`)
        $('#prosody-modal button.btn-ok').html(`<span class="mif-checkmark icon fg-green"></span><span class="tooltiptext">Apply ${this.control}</span>`)
        $('#prosody-modal button.btn-clr').html(`<i class="fas fa-eraser"></i><span class="tooltiptext">Clear ${this.control}</span>`)


        if (this.optionsWindowReady) return;
        for (let s in sliders) {
            const labels = sliders[s].labels;
            const slider = sliders[s].instance = Metro.getPlugin(`#prosody-${s}-slider`, 'slider');


            slider._orig_hint = sliders[s].instance._hint;
            slider._hint = function () {
                const hint = slider.slider.find('.hint');
                const val = slider.val();
                let text = labels[val] ? `${labels[val]} (${val}%)` : `${val}%`;
                //let text = `${val}%`;
                hint.text(text)
            }
        }




        this.optionsWindowReady = true;
    }

}






export default Prosody;



window.prosodyDialogActions = [
    {
        caption: "",
        cls: "btn-ok js-dialog-close button success cycle outline btn-dlg tooltip",
        onclick: applyChanges
    },
    {
        caption: "",
        cls: "btn-cancel js-dialog-close button alert cycle outline btn-dlg tooltip",
        onclick: cancelChanges
    },
    {
        caption: "",
        cls: "btn-clr js-dialog-close button alert cycle btn-dlg tooltip",
        onclick: clearProp
    }

];

function cancelChanges() {



}

function clearProp() {
    if ($('#prosody-modal .prosody-slider[active="true"] div[data-role="slider"]').length !== 1) return;

    //first get the current edited attributes : rate, volume or pitch
    const attrId = $('#prosody-modal .prosody-slider[active="true"] div[data-role="slider"]').data('name');

    const updateCfg = {};
    updateCfg[attrId] = '';

    VoiceEditor.setNode(updateCfg);

    //editor.history._lock = false;
    updateSSML();
    //editor.history.push();

}
function applyChanges() {

    if (!editor) return;

    const labelVal = {};
    for (let s in sliders) {
        const slider = sliders[s].instance;
        const labels = sliders[s].labels;
        const val = slider.val();
        labelVal[s] = labels[val] || val + '%';
        //labelVal[s] = val + '%';



        if (labelVal[s] == 'default') labelVal[s] = '';
    }

    VoiceEditor.setNode(labelVal);


    //editor.history._lock = false;
    updateSSML();
    //editor.history.push();
}





function semitone2percent(semitone) {
    let adjust = 2; //for AWS
    adjust = 4; // for Ms Azure
    adjust = 1; // google
    //AWS required divide by 2 to adjust
    return ((Math.pow(2, semitone / 12) * 100) - 100) / adjust;
}