//const cld = require('cld');
import { guessLanguage } from '../../../vendor/guessLanguage.js';
import { isAR } from './artools';

const nlp = require('compromise')
nlp.extend(require('compromise-sentences'))

export function splitAfter(text: string, sep: string) {
    return nlp(text).splitAfter(sep).out('array');
}
export function splitSentences(text: string) {
    return nlp(text).sentences().out('array');
}

function detectLang(text: string) {
    return new Promise(resolve => guessLanguage.detect(text, resolve));
}

export async function lang(text: string) {
    let language = await detectLang(text);
    if (language != 'unknown') return language;

    language = langFromCharacters(text);
    if (language != 'unknown') return language;

    if (language == 'unknown') {
        if (isAR(text)) return 'ar';
    }

    return await detectLang(text);


    // cld.detect(text).then(result => {
    //     if (result.languages && result.languages.length > 0)
    //         resolve(result.languages[0].code);
    // }).catch((error) => {
    //     guessLanguage.detect(text, function (language) {
    //         resolve(language);
    //     });

    // });
}




function langFromCharacters(text: string) {
    return new Promise(resolve => {


        //Dictionary for Unicode range of the languages
        var langdic = {
            "ar": /[\u0600-\u06FF]/,
            "fa": /[\u0750-\u077F]/, //Persian
            "he": /[\u0590-\u05FF]/,
            "Syriac": /[\u0700-\u074F]/,
            "bn": /[\u0980-\u09FF]/, //Bengali
            "Ethiopic": /[\u1200-\u137F]/,
            "el": /[\u0370-\u03FF]/, //Greek and Coptic
            "ka": /[\u10A0-\u10FF]/, //Georgian
            "th": /[\u0E00-\u0E7F]/,//Thai
            "en": /^[a-zA-Z]+$/
            //add other languages her
        }
        //const keys = Object.keys(langdic); //read  keys
        //const keys = Object.values(langdic); //read  values
        //const keys = Object.entries(langdic); // read  keys and values from the dictionary
        Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
            if (value.test(text) == true) {   //Check Unicode to see which one is true
                return resolve(key); //print language name if unicode true   
            }
        });

        return resolve('en');
    })
}