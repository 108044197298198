import Prosody from './prosody'


const pitch = new Prosody({
    control: 'pitch',
    title: 'Pitch',
    name: 'ssml-pitch',
    icon: 'mif-record_voice_over',
    cssClass: 'tbtn-pitch'
});

const plugin = pitch.plugin;
export default plugin;