import EventEmitter from 'eventemitter3'
import BrowserIPC from '../browser/BrowserIPC'
import BrowserRPC from '../browser/BrowserRPC'

BrowserRPC.init();

// const DefaultEmitter: any = function () { };

// DefaultEmitter.prototype.on = (...args) => {
//     console.warn('DefaultEmitter.on unimplemented', args);
// };
// DefaultEmitter.prototype.emit = (...args) => {
//     console.warn('DefaultEmitter.emit unimplemented', args);
// };




// var EventEmitter: any = DefaultEmitter;
// try {
//     EventEmitter = require('eventemitter3');
//     console.log('EventEmitter = ', EventEmitter);

// } catch (ex) {
//     console.log('EventEmitter EX = ', EventEmitter);
// }



var ipc: any = BrowserIPC;
try {
    ipc = require('electron').ipcRenderer;
    console.log('IPC = ', ipc);

} catch (ex) {
    console.log('EX = ', ex);
}
//const ipc: any = require('electron').ipcRenderer;


const events: typeof EventEmitter = new EventEmitter();

['new', 'maximize', 'minimize', 'isMaximized', '']
const window = {
    new: () => ipc.invoke('window:new'),
    maximize: () => ipc.invoke('window:maximize'),
    minimize: () => ipc.invoke('window:minimize'),
    isMaximized: () => ipc.invoke('window:isMaximized'),
    close: () => ipc.invoke('window:close'),
    download: (url: string) => ipc.invoke('window:download', url),
    debug: () => ipc.invoke('window:debug')

}

const menu = {
    file: {
        save: (content: string, filename?: string) => ipc.invoke('menu:save', content, filename),
        open: (newWindow?: any) => ipc.invoke('menu:open', newWindow),
        quit: () => ipc.invoke('menu:quit')
    }
}

const analytics = {

    trackEvent: (category, action, label?, value?) => ipc.invoke('analytics:trackEvent', category, action, label, value),
    trackScreen: (screenName, appName, appVersion?) => ipc.invoke('analytics:trackScreen', screenName, appName, appVersion),

}


const auth = {
    getProfile: () => ipc.invoke('auth:getProfile'),
    signIn: () => ipc.invoke('auth:signIn'),
    signOut: () => ipc.invoke('auth:signOut'),
    refresh: () => ipc.invoke('auth:refresh')
}


ipc.on('contentLoaded', (event: Event, message: any) => {
    events.emit('contentLoaded', message);
})

const RPC = { window, menu, events, analytics, auth }

export default RPC;