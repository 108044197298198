import CanvasSpliner from '../../vendor/CanvasSpliner.js'
import WaveSurfer from '../../vendor/wavesurfer.js';
import LZString from 'lz-string';
import AppSettings from '../settings'
import api from '../api'
export default class ProsodyEditor {
    public selector: string;
    public domContainer: HTMLElement;
    public host: string;
    public port: number;
    private url: string;
    private _changed: boolean;
    private wavesurfer: any;
    private endtime: number;
    private pitchCS: any;
    private durCS: any;
    private partId: string;
    private fxName: string;
    private needPlay: boolean;
    private pitchData: any[];
    private durData: any[];

    private mouseWheelEvt: any = null;

    constructor(selector) {
        this.selector = selector;
        this.domContainer = document.querySelector(selector);
        this._changed = false;
        this.wavesurfer = null;
        this.endtime = 0;
        this.pitchCS;
        this.durCS;
        this.partId;
        this.fxName;
        this.needPlay = false;


        this.initDOM();



    }
    initDOM() {
        const div = document.createElement('div');
        div.innerHTML = `<div id="pitchCS" class="cspliner">
                <div id="prosody-waveform" class="waveform"></div></div>
                <div id="durCS" class="cspliner"></div>`
        this.domContainer.appendChild(div);
    }
    getPitchInterpVal(idx) {
        const px = this.pitchData[idx][0];
        const _py = this.pitchData[idx][1];
        const pval = this.pitchCS.getValue(px / this.endtime);
        const py = _py * (1 + (pval - 0.5) * 2);
        return [px, py]
    }

    async getData(partId) {
        const url = `/get/PitchTier/${partId}`;

        const result = await api.get(url).catch(error => {
            console.error(error);
        });

        return result;
    }
    async postData({ pitchData, durData }) {
        var reqBody = { fileId: this.partId, fxName: this.fxName, pitchData, durData };
        const result = await api.post('/setPitchTier', reqBody).catch(error => {
            console.error(error);
        });

        const waveFile = `${AppSettings.appSettings.serverUrl}/chunk.tmp/${result.id}`;
        const abuffer = await api.getArrayBuffer(waveFile);
        return this.wavesurfer.loadArrayBuffer(abuffer);
    }
    async updateSound() {
        let newPitchData = [];
        let newDurData = [];

        for (let i = 0; i < this.pitchData.length; i++) {
            newPitchData.push(this.getPitchInterpVal(i))
        }

        //const dymid = this.durCS._pointCollection._max.y / 2;
        //const dmin = this.durCS._pointCollection._min;
        const dmax = this.durCS._pointCollection._max;



        for (let durPoint of this.durCS._pointCollection._points) {
            const dx = (durPoint.x * this.endtime) / dmax.x;
            const val = (durPoint.y / dmax.y) - 0.5;
            const dy = val < 0 ? 1 - (val * 3) : 1 - (val * 2);

            newDurData.push([dx, dy]);
        }

        this._changed = false;

        const pdata = this.pitchCS ? this.pitchCS._pointCollection._points : [];
        const pitchData = pdata.map(e => [e.x, e.y]);

        const ddata = this.durCS ? this.durCS._pointCollection._points : [];
        const durData = ddata.map(e => [e.x, e.y]);

        return { pitchData, durData }
    }

    getPitchData() {
        const data = this.pitchCS ? this.pitchCS._pointCollection._points : [];
        const strDrawData = JSON.stringify(data.map(e => [e.x, e.y]));


        // let newPitchData = [];
        // for (let i = 0; i < this.pitchData.length; i++) {
        //     newPitchData.push(this.getPitchInterpVal(i))
        // }
        // const strPitchData = JSON.stringify(newPitchData);
        //return LZString.compressToEncodedURIComponent(strDrawData + ':' + strPitchData);
        return LZString.compressToEncodedURIComponent(strDrawData);
    }

    getDurData() {
        const data = this.durCS ? this.durCS._pointCollection._points : [];
        const strDrawData = JSON.stringify(data.map(e => [e.x, e.y]));

        // const dmax = this.durCS._pointCollection._max;


        // let newDurData = [];
        // for (let durPoint of this.durCS._pointCollection._points) {
        //     const dx = (durPoint.x * this.endtime) / dmax.x;
        //     const val = (durPoint.y / dmax.y) - 0.5;
        //     const dy = val < 0 ? 1 - (val * 3) : 1 - (val * 0.9);
        //     newDurData.push([dx, dy]);
        // }
        //const strDurData = JSON.stringify(newDurData);

        return LZString.compressToEncodedURIComponent(strDrawData);
    }

    destroy() {
        if (this.pitchCS && this.pitchCS._canvas) this.pitchCS._canvas.remove();
        if (this.durCS && this.durCS._canvas) this.durCS._canvas.remove();
    }
    reset() {

        this.pitchCS._pointCollection._points = [];
        if (this.pitchCS._pointCollection._points.length <= 0) {
            this.pitchCS.add({ x: 0, y: 0.5, xLocked: true, safe: true });
            for (let i = 1; i < 10; i++) {
                this.pitchCS.add({ x: i / 10, y: 0.5 });
            }
            this.pitchCS.add({ x: 1, y: 0.5, xLocked: true, safe: true });
        }

        this.durCS._pointCollection._points = [];
        if (this.durCS._pointCollection._points.length <= 0) {
            this.durCS.add({ x: 0, y: 0.5, xLocked: true, safe: true });
            for (let i = 1; i < 10; i++) {
                this.durCS.add({ x: i / 10, y: 0.5 });
            }
            this.durCS.add({ x: 1, y: 0.5, xLocked: true, safe: true });
        }

        this.pitchCS.draw();
        this.durCS.draw();
    }
    async load(keyId, pitchDataStr?, durDataStr?) {
        let fxName = '';
        let partId = keyId;
        if (keyId.indexOf('#') > 0) {
            const tokens = keyId.split('#'); //

            fxName = tokens[0].replace('L4FX', '');
            partId = tokens[tokens.length - 1];
        }

        this.partId = partId;
        this.fxName = fxName;

        const res = await this.getData(partId);
        const json = JSON.parse(res.result);
        this.pitchData = json.data;
        this.endtime = json.endtime;


        if (this.pitchCS && this.pitchCS._canvas) this.pitchCS._canvas.remove();
        if (this.durCS && this.durCS._canvas) this.durCS._canvas.remove();

        const width = Math.max(800, (~~this.endtime) * 200);
        const height = 200;
        this.pitchCS = new CanvasSpliner.CanvasSpliner("pitchCS", width, height);
        this.durCS = new CanvasSpliner.CanvasSpliner("durCS", width, height / 2);
        this.durCS._borderStyle = this.pitchCS._borderStyle = { in: '', out: '' };
        this.pitchCS._canvas.style.border = '';
        this.durCS._canvas.style.border = '';
        this.pitchCS.setGridStep(0.1, 0.05);
        this.durCS.setGridStep(0.1, 0.05);
        //pitchCS.setBackgroundColor("rgba(50, 50, 50, 1.0)");
        //durCS.setBackgroundColor("rgba(50, 50, 50, 1.0)");

        this.pitchCS.setSplineType('monotonic');
        this.durCS.setSplineType('monotonic');

        this.pitchCS.setControlPointRadius(4);
        this.durCS.setControlPointRadius(4);




        if (pitchDataStr) {
            const data = LZString.decompressFromEncodedURIComponent(pitchDataStr);
            const tokens = data.split(':');
            const pitchData: any[] = JSON.parse(tokens[0]);//first part contains points data - second part contains actual prosody data

            const maxX = this.pitchCS._pointCollection._max.x;
            const maxY = this.pitchCS._pointCollection._max.y;
            const first = pitchData.shift();
            const last = pitchData.pop();
            this.pitchCS.add({ x: first[0] / maxX, y: first[1] / maxY, xLocked: true, safe: true });
            this.pitchCS.add({ x: last[0] / maxX, y: last[1] / maxY, xLocked: true, safe: true });

            for (let point of pitchData) {
                this.pitchCS.add({ x: point[0] / maxX, y: point[1] / maxY });
            }
        }

        if (this.pitchCS._pointCollection._points.length <= 0) {
            this.pitchCS.add({ x: 0, y: 0.5, xLocked: true, safe: true });
            for (let i = 1; i < 10; i++) {
                this.pitchCS.add({ x: i / 10, y: 0.5 });
            }
            this.pitchCS.add({ x: 1, y: 0.5, xLocked: true, safe: true });
        }


        if (durDataStr) {
            const data = LZString.decompressFromEncodedURIComponent(durDataStr);
            const tokens = data.split(':');

            const durData: any[] = JSON.parse(tokens[0]);
            const maxX = this.durCS._pointCollection._max.x;
            const maxY = this.durCS._pointCollection._max.y;
            const first = durData.shift();
            const last = durData.pop();
            this.durCS.add({ x: first[0] / maxX, y: first[1] / maxY, xLocked: true, safe: true });
            this.durCS.add({ x: last[0] / maxX, y: last[1] / maxY, xLocked: true, safe: true });

            for (let point of durData) {
                this.durCS.add({ x: point[0] / maxX, y: point[1] / maxY });
            }
        }

        if (this.durCS._pointCollection._points.length <= 0) {
            this.durCS.add({ x: 0, y: 0.5, xLocked: true, safe: true });
            for (let i = 1; i < 10; i++) {
                this.durCS.add({ x: i / 10, y: 0.5 });
            }
            this.durCS.add({ x: 1, y: 0.5, xLocked: true, safe: true });
        }



        const changed = () => { this._changed = true; }
        this.durCS.on("releasePoint", changed)
        this.pitchCS.on("releasePoint", changed)


        this.durCS.on("pointAdded", changed)
        this.pitchCS.on("pointAdded", changed)


        this.durCS.on("pointRemoved", changed)
        this.pitchCS.on("pointRemoved", changed)



        const waveFile = `${AppSettings.appSettings.serverUrl}/chunk/${partId}`;
        if (!this.wavesurfer) {

            this.wavesurfer = WaveSurfer.create({
                container: document.querySelector('#prosody-waveform'),
                barWidth: 2,
                responsive: true,
                waveColor: '#eee',
                backgroundColor: 'rgba(50, 50, 50, 1.0)',
                barHeight: 1, // the height of the wave
                barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy format,
                height: 200
            });
            this.wavesurfer.on('ready', () => {
                if (this.needPlay) {
                    this.wavesurfer.play();
                    this.needPlay = false;
                }
            });

        }
        this.wavesurfer.container.style.width = width + 'px';
        const abuffer = await api.getArrayBuffer(waveFile);
        this.wavesurfer.loadArrayBuffer(abuffer);
        //this.wavesurfer.load(waveFile);

        this.syncDivScroll();

        if (pitchDataStr && durDataStr) {
            const pitchDurData = await this.updateSound();
            return this.postData(pitchDurData);
        }



    }

    syncDivScroll() {
        var isSyncingLeftScroll = true;
        var isSyncingRightScroll = true;
        var leftDiv = document.getElementById('pitchCS');
        var rightDiv = document.getElementById('durCS');

        leftDiv.onscroll = function () {
            if (!isSyncingLeftScroll) {
                isSyncingRightScroll = true;
                rightDiv.scrollLeft = this.scrollLeft;
            }
            isSyncingLeftScroll = false;
        }

        rightDiv.onscroll = function () {
            if (!isSyncingRightScroll) {
                isSyncingLeftScroll = true;
                leftDiv.scrollLeft = this.scrollLeft;
            }
            isSyncingRightScroll = false;
        }


        if (!this.mouseWheelEvt) {
            this.mouseWheelEvt = true;
            document.querySelector('#CSContainer').addEventListener('wheel', function (e: any) {
                if (e.type != 'wheel') {
                    return;
                }
                let delta = ((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1;
                delta = delta * (-50);
                document.querySelector('#pitchCS').scrollLeft -= delta;
                // safari needs also this

                e.preventDefault();
            });
        }
    }



}

