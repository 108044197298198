
export const config = {
    appWindow: {
        dimensions: {
            width: 1024,
            height: 600,
            minWidth: 1024,
            minHeight: 600,
        }
    },
    fileFormat: {
        name: 'Talky.Studio file',
        extensions: ['tlk']
    },
    auth0: {
        auth0Domain: "talky.eu.auth0.com",
        clientId: "CbGKB07QwlOCfZx8ezxr5RIF1A7y8ks0",
        apiIdentifier: "https://api.auth0.talky.studio",
        webClientId: 'neqeAUv2lGJQoJGJKHLV9wObZCWjnTkr'
    }
}