import App from './App';
import RPC from './rpc';
import api from './api'

export const menu = {
    File: {
        children: {
            New: {
                handler: App.cmd.newWindow,
                shortcut: 'Ctrl+N'
            },
            Open: {
                handler: App.cmd.openFile,
                shortcut: 'Ctrl+O',
                divide: true
            },
            Save: {
                handler: App.cmd.saveFile,
                shortcut: 'Ctrl+S',
            },
            SaveAs: {
                label: 'Save As...',
                handler: App.cmd.saveFileAs,
                shortcut: 'Ctrl+Shift+S',
                divide: true
            },
            Close: {
                handler: () => console.log('close')
            }
        }
    },
    Edit: {
        children: {
            Undo: {
                handler: App.cmd.undo,
                shortcut: 'Ctrl+Z'
            },
            Redo: {
                handler: App.cmd.redo,
                shortcut: 'Ctrl+Y'
            }
        }
    },
    Help: {
        children: {
            GettingStarted: {
                label: 'Getting Started',
                handler: () => console.log('getting started'),
                divide: true
            },
            About: {
                handler: () => console.log('About')
            }
        }
    },
    Test: {
        children: {
            Debug: {
                handler: async () => {
                    RPC.window.debug();
                },
                shortcut: 'Ctrl+Alt+D'
            },
            DevServer: {
                label: 'Switch to local server',
                handler: () => {
                    VoiceEditor.setEndPoint('http://localhost:3001');
                }
            },
            AuthProfile: {
                label: 'Auth Profile',
                handler: async () => {
                    const profile = await RPC.auth.getProfile();
                    console.log(profile);
                },
                divide: false
            },
            SignIn: {
                label: 'Sign In',
                handler: async () => {
                    const profile = await RPC.auth.signIn();
                    console.log(profile);
                },
                divide: false
            },
            SignOut: {
                label: 'Sign Out',
                handler: async () => {
                    RPC.auth.signOut();
                },
                divide: false
            },
            PrivateReq: {
                label: 'Private Request',
                handler: async () => {

                    api.post('/private').then(response => {
                        console.log(response);
                    }).catch(error => {
                        console.error(error);
                    });

                },
                divide: true
            },

            Test: {
                label: 'Test',
                handler: async () => {
                    console.log('test');
                }
            },

            Quit: {
                label: 'Quit',
                handler: async () => {
                    RPC.menu.file.quit();
                }
            }
        }
    }
}
