import RPC from './rpc';
import AppSettings from './settings';


export default class api {
    static async call(method: string, url: string, reqBody?: any) {

        if (!url.startsWith('http') && url.startsWith('/'))
            url = AppSettings.appSettings.serverUrl + url;


        const body = typeof reqBody == 'string' ? reqBody : JSON.stringify(reqBody);


        const profile = await RPC.auth.getProfile();

        const params = {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body
        };

        //if the user is authentified, use the access token
        if (profile) {
            params.headers['authorization'] = `Bearer ${profile.accessToken}`;
        }


        let rawResponse = await fetch(url, params)

        if (rawResponse.status == 401) {
            console.log('Session expired, trying to refresh');
            profile = await RPC.auth.refresh();

            rawResponse = await fetch(url, params)
        }

        if (!rawResponse.ok || rawResponse.status !== 200) {
            let error = rawResponse.statusText;

            try {
                const result = await rawResponse.json();
                error = result && result.error ? result.error : rawResponse.statusText
            } catch (ex) {
            }

            throw new Error(error);
        }

        //TODO : handle disconnected case


        return rawResponse;
    }

    static async post(url, body?) {
        const raw: Response = await this.call('POST', url, body);
        const result = await raw.json();
        return result;

    }

    static async get(url) {
        const raw: Response = await this.call('GET', url)
        const result = await raw.json();
        return result;
    }

    static async getArrayBuffer(url) {
        const raw: Response = await this.call('GET', url)
        const result = await raw.arrayBuffer();
        return result;
    }
}