

const _events = {

}

export default class BrowserIPC {

    static on(eventName, callback) {
        console.warn('BrowserIPC.on unimplemented', eventName);
    }

    static async invoke(eventName, ...args) {

        if (typeof _events[eventName] == 'function') {
            return _events[eventName].apply(null, args);
        }
        console.warn('BrowserIPC.invoke unimplemented', eventName);
        throw new Error('unknown method ' + eventName)

    }

    static async handle(eventName, handler) {
        _events[eventName] = handler;
    }
};

