import Prosody from './prosody'


const rate = new Prosody({
    control: 'rate',
    icon: 'mif-forward',
    title: 'Rate',
    name: 'ssml-rate',
    cssClass: 'tbtn-rate'
});

const plugin = rate.plugin;


export default plugin;