//const LZString = require('lz-string');

const defaultServerURL = 'https://api.eu-1.talky.studio';

let appSettings = {
    serverUrl: defaultServerURL,
    ttsEndpoint: `${defaultServerURL}/tts`,
    actorsEndpoint: `${defaultServerURL}/actors`,
    fxEndpoint: `${defaultServerURL}/fx`,
    fxListEndpoint: `${defaultServerURL}/fxList`,
    filesEndpoint: `${defaultServerURL}/download/`,
}

let userSettings = {
    favActors: {},
    favFx: {},
    editor: {},
    token: null
}


//LZString.compressToUTF16(string);
if (localStorage.getItem('userSettings')) {
    userSettings = { ...userSettings, ...JSON.parse(localStorage.getItem('userSettings')) }
}

if (localStorage.getItem('appSettings')) {
    appSettings = { ...appSettings, ...JSON.parse(localStorage.getItem('appSettings')) }
}
const cache = JSON.parse(localStorage.getItem('cache')) || {};


if (!localStorage.getItem('userSettings')) saveUserSettings();
if (!localStorage.getItem('appSettings')) saveAppSettings();

function saveUserSettings() {
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
}

function saveAppSettings() {
    localStorage.setItem('appSettings', JSON.stringify(appSettings));
}

function saveCache() {
    localStorage.setItem('cache', JSON.stringify(cache));
}

function save() {
    saveUserSettings();
    saveAppSettings();
    saveCache();
}



const settings = { cache, userSettings, appSettings, save, saveUserSettings, saveAppSettings, saveCache };

export default settings;