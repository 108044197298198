import VoiceEditor from './editor'
import AppSettings from '../settings'
import CircularAudioWave from '../../vendor/circular-audio-wave.js'
declare var $: any;

let allActors = {};
let actorsByLang = {};
let actorsByCountry = {};

let cawave: any;
let tileIdx = 1;
function createActorTile(actor: any) {
    const genderImg = actor.gender == 'M' ? './img/male.png' : './img/female.png';
    let img = `<img src="${genderImg}"/>`;
    if (!actor.img) actor.img = `${actor.name}.png`;
    if (actor.img) img = `<img src="./img/${actor.img}" orig-src="./img/${actor.img}" alt-src="${genderImg}"/>`;

    const favState = AppSettings.userSettings.favActors[actor.name] ? 'active' : '';

    const tile = $(`
    <div class="tile" data-lang="${actor.langCode}" data-actor="${actor.name}" data-actor-id="${actor.id}">
      <div class="favbox">
        <div class="fav-btn"><span class="favme mif-heart ${favState}"></span></div>
        <div class="preview-btn"><button rel="#cawave-${tileIdx}" class="button dark cycle small"><span class="mif-play"></span></button></div>
      </div>

        <a href="#" data-lang="${actor.langCode}" data-actor="${actor.name}">
        <div class="audiowave" id="cawave-${tileIdx++}"></div>
        <span class="badge-lang">${actor.langCode}</span>
        ${img}
        <span class="badge-actor">${actor.displayLTR || actor.name}</span>
        
        <span class="badge-country flag-${actor.countryCode} flag-icon flag-icon-${actor.countryCode}">&nbsp;</span>
        </a>
    </div>
`);

    tile.find('.preview-btn button').on('click', function () {
        const elt = $(this).closest('.tile').find('.audiowave');

        if (!elt) return;
        console.log('>> wave = ', elt.attr('id'));

        if (cawave && cawave.playing) {
            const eltId = cawave.id;
            cawave.onEnd();
            cawave.stop();
            cawave.onEnd = undefined;

            if (eltId == elt.attr('id')) {
                elt.html('');
                elt.removeAttr('style');
                elt.removeAttr('_echarts_instance_');
                return;
            }
        }



        cawave = new CircularAudioWave(elt[0]);
        //window.wave = cawave;
        cawave.id = elt.attr('id');
        cawave.elt = elt;
        cawave.loadAudio('./audio/input.wav').then((e: any) => cawave.play())
        cawave.onEnd = () => {
            cawave.elt.fadeOut('fast', () => {
                const rel = $(this).attr('rel');
                const relItem = $(rel);
                relItem.html('');
                relItem.removeAttr('style');
                relItem.removeAttr('_echarts_instance_');
            })
        }

    })

    tile.find('a').on('click', function () {
        console.log('click');
        VoiceEditor.setVoice($(this).data('actor'));
    });

    // Favorite Button - Heart
    tile.find('.favme').on('click', function () {
        const actorName = $(this).closest('.tile').attr('data-actor');
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {

            if (actorName)
                AppSettings.userSettings.favActors[actorName] = true;
        }
        else {
            if (AppSettings.userSettings.favActors[actorName])
                delete AppSettings.userSettings.favActors[actorName];
        }
        AppSettings.saveUserSettings();
    });

    /* when a user clicks, toggle the 'is-animating' class */
    tile.find(".favme").on('click touchstart', function () {
        $(this).toggleClass('is_animating');
    });

    /*when the animation is over, remove the class*/
    tile.find(".favme").on('animationend', function () {
        $(this).toggleClass('is_animating');
    });
    return tile;
}
function sortActors() {
    //move favorites to top
    const favorite = [];
    for (let actor of allActors) {
        if (AppSettings.userSettings.favActors[actor.name])
            favorite.push(actor.id);
        else
            $('#inside-lang-bar .all').prepend($(`#inside-lang-bar .all .tile[data-actor-id=${actor.id}]`));
    }

    //move favorites to top
    for (let actorId of favorite)
        $('#inside-lang-bar .all').prepend($(`#inside-lang-bar .all .tile[data-actor-id=${actorId}]`));


}

export function updateContextualActors(lang, actorName) {
    if (lang && actorsByLang[lang] && actorsByLang[lang].length != 0) {
        console.log(lang)

        $('#inside-lang-bar .all .tile.active').removeClass('active');
        $(`#inside-lang-bar .all .tile[data-actor=${actorName}]`).addClass('active');

        //$('#inside-lang-bar .all').prepend($(`#inside-lang-bar .all .tile.active`))

        sortActors();


        const favorite = [];
        for (let actor of actorsByLang[lang]) {
            if (AppSettings.userSettings.favActors[actor.name])
                favorite.push(actor.id);
            else
                $('#inside-lang-bar .all').prepend($(`#inside-lang-bar .all .tile[data-actor-id=${actor.id}]`));
        }

        //move favorites to top
        for (let actorId of favorite)
            $('#inside-lang-bar .all').prepend($(`#inside-lang-bar .all .tile[data-actor-id=${actorId}]`));
    }

}

export function updateActorsList() {

    $('#inside-lang-bar .contextual').html('');
    $('#inside-lang-bar .all').html('');

    return new Promise(resolve => {
        loadActors().then(actorsList => {
            actorsList.forEach(actor => {
                const tile = createActorTile(actor);
                $('#inside-lang-bar .all').append(tile);
            })

            sortActors();


            $('img').on("error", function () {
                const altSrc = $(this).attr('alt-src');
                if (altSrc) {
                    $(this).attr('alt-src', '');
                    $(this).attr('src', altSrc);
                }

            });
            resolve();
        })
    })
}


async function loadActors(lang) {
    // //initScrollers();

    const actors = await VoiceEditor.loadActors();
    allActors = actors;
    if (actors && actors.length) {
        actorsByLang = {};
        actorsByCountry = {};
        for (let i = 0; i < actors.length; i++) {
            const actor = actors[i];
            actor.id = 'actor-' + i;
            const lng = actor.lang.split('-');

            const lang = lng[0].toLowerCase();
            const country = lng[1].toLowerCase();

            actor.langCode = lang;
            actor.countryCode = country;
            if (!actorsByLang[lang]) actorsByLang[lang] = [];
            if (!actorsByCountry[country]) actorsByCountry[country] = [];
            actorsByLang[lang].push(actor);
            actorsByCountry[country].push(actor);
        }
    }

    //allActors = actors;
    return actors;
}

let scrolling;
const scrollspeed = 4;
let speedMultiplicator = 1;
function initScrollers() {
    const bscroller = $('.bottom-scroller');
    bscroller.on('mouseover', function (e) {

        clearTimeout(scrolling);
        scroll_down(this)
    })
    bscroller.on('mouseout', function () {
        clearTimeout(scrolling);
        scrolling = null;
    })

    const tscroller = $('.top-scroller');
    tscroller.on('mouseover', function (e) {
        clearTimeout(scrolling);
        scroll_up(this)
    })
    tscroller.on('mouseout', function () {
        clearTimeout(scrolling);
        scrolling = null;
    })

    document.addEventListener('mousemove', (e) => {
        if (!scrolling) return;
        let distance = Math.max(1, document.body.offsetHeight - e.clientY);
        if (distance <= 40) //bottom scrolling
        {

        }
        else //top scrolling
        {
            distance = Math.max(1, e.clientY - 34);
        }

        speedMultiplicator = 1 / (distance / 10);

    });
}

function scroll_up(scroller) {
    const allTiles = scroller.parentElement.querySelector('.all');
    //const contextualTiles = scroller.parentElement.querySelector('.contextual');

    if (allTiles) allTiles.style.top = Math.min(0, ((parseInt(allTiles.style.top) || 0) + scrollspeed * speedMultiplicator)) + 'px';
    //if (contextualTiles) contextualTiles.style.top = ((parseInt(contextualTiles.style.top) || 0) + scrollspeed) + 'px';

    scrolling = window.setTimeout(function () {
        scroll_up(scroller);
    }, 16);
}

function scroll_down(scroller) {
    const allTiles = scroller.parentElement.querySelector('.all');
    const height = allTiles.offsetHeight - document.body.offsetHeight + 150;
    //const contextualTiles = scroller.parentElement.querySelector('.contextual');

    if (allTiles) allTiles.style.top = Math.max(-height, ((parseInt(allTiles.style.top) || 0) - scrollspeed * speedMultiplicator)) + 'px';
    //if (contextualTiles) contextualTiles.style.top = ((parseInt(contextualTiles.style.top) || 0) - scrollspeed) + 'px';


    scrolling = window.setTimeout(function () {
        scroll_down(scroller);
    }, 16);
}