import { updateSSML } from '../utils/ttstools'

const tagName = 'breath';
const commandName = `ssml-${tagName}`;

let lastActiveElement;
export default {
    name: commandName,
    display: 'command',

    title: 'Breath',
    // This icon uses Font Awesome
    innerHTML: '<i class="mif-wind"></i>',
    buttonClass: 'ui button tbtn-breath',
    add: function (core, targetElement) {
        const context = core.context;
        context[commandName] = {
            targetButton: targetElement
        };


        const span = document.createElement('span')
        span.className = 'txt';
        span.innerText = `[${tagName}]`;
        targetElement.appendChild(span);

    },
    active: function (element) {
        //const curRange = this.getRange();

        var regex = new RegExp(`^${tagName}$`, 'i')
        if (lastActiveElement) {
            console.log('active check ', lastActiveElement);
            if (!lastActiveElement.nextSibling || lastActiveElement.nextSibling.nodeType !== 3 || lastActiveElement.nextSibling.textContent != ' ')
                lastActiveElement.after(document.createTextNode(' '));
        }

        if (!element) {
            this.util.removeClass(this.context[commandName].targetButton, 'active');
            if (lastActiveElement) lastActiveElement.classList.remove('active');
        } else if (regex.test(element.nodeName)) {
            this.util.addClass(this.context[commandName].targetButton, 'active');
            if (lastActiveElement) lastActiveElement.classList.remove('active');
            element.classList.add('active');


            lastActiveElement = element;
            return true;
        }

        return false;
    },
    action: function () {
        const curRange = this.getRange();
        if (!curRange.collapsed) return false;


        this.history.push();
        this.history._lock = true;

        const create = (curRange.startContainer.nodeName != tagName.toUpperCase() && curRange.startContainer.parentElement.nodeName != tagName.toUpperCase())
            || !this.util.hasClass(this.context[commandName].targetButton, 'active');
        if (create) {
            const newNode = this.util.createElement(tagName);


            //newNode.setAttribute('tagname', tagName);
            newNode.setAttribute('data-label', tagName);

            newNode.className = `SSML ${commandName}`;
            //this.nodeChange(newNode, ['.SSML', `.${commandName}`], null, false);
            curRange.insertNode(newNode);

            newNode.innerHTML = '&nbsp;'
            //newNode.after(document.createTextNode("&nbsp;"));


            this.removeRange();


        } else {

            //this.nodeChange(null, [`.${commandName}`], [tagName], false);
            if (curRange.startContainer.nodeName == tagName.toUpperCase())
                curRange.startContainer.remove();

            if (curRange.startContainer.parentElement.nodeName == tagName.toUpperCase())
                curRange.startContainer.parentElement.remove();
            this.removeRange();


        }

        this.history._lock = false;
        updateSSML();


        this.history.push();

    }
}
