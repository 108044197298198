

import { updateSSML } from '../utils/ttstools'

const commandName = 'ssml-clearformat';
export default {
    name: commandName,
    display: 'command',

    title: 'Sub',

    // This icon uses Font Awesome
    innerHTML: '<i class="fas fa-eraser"></i><span class="txt">[clear form]</span>',
    buttonClass: 'ui button tbtn-clearformat',
    add: function (core, targetElement) {
        const context = core.context;
        context[commandName] = {
            targetButton: targetElement
        };


        const span = document.createElement('span')
        span.className = 'txt';
        span.innerText = `[clear]`;
        targetElement.appendChild(span);

    },

    action: function () {
        let curRange = this.getRange();
        if (curRange.collapsed) {
            if (!curRange.startContainer.parentElement.innerText.trim()) return;
            $(curRange.startContainer.parentElement).selectText();
            curRange = this.getRange();
        }




        if (curRange.startContainer == curRange.endContainer) {
            let container = curRange.startContainer;
            if (container.nodeType == 3) container = container.parentElement;

            if (!container.classList.contains('tts-chunk')) {

                container.innerHTML = container.innerText;
                container.outerHTML = container.innerHTML;

                updateSSML();
                this.history.push();

            }
        }
        else {

            this.history.push();
            this.history._lock = true;

            const container: any = document.createElement('span');
            container.appendChild(curRange.extractContents());
            curRange.insertNode(container);

            const paragraphs = [...container.querySelectorAll('p')];

            if (paragraphs.length) { //multi-paragraph selection
                //window.paragraphs = paragraphs;
                paragraphs.forEach(p => p.className = '__editing');
                //container.outerHTML = container.innerHTML;

                const previous: any = container.previousSibling;
                const next = container.nextElementSibling;

                if (previous) {
                    const span = previous.querySelector('span:last-child');
                    if (span) {
                        const p = paragraphs.shift();
                        if (p) {
                            span.innerHTML = span.innerHTML + p.innerText;
                            p.remove()
                        }
                    }
                }

                if (next) {
                    const span = next.querySelector('span:first-child');
                    if (span) {
                        const p = paragraphs.pop();
                        if (p) {
                            span.innerHTML = p.innerText + span.innerHTML;
                            p.remove()
                        }
                    }
                }

                paragraphs.forEach(p => {
                    p.querySelectorAll('span').forEach(span => {
                        span.removeAttribute('data-pitch');
                        span.removeAttribute('data-dur');
                        span.innerHTML = span.innerText
                    });
                })

            }
            else { // selection within the same paragraph
                container.innerHTML = container.innerText;
                container.outerHTML = container.innerHTML;
            }


            this.history._lock = false;
            updateSSML();
            this.history.push();


        }






    }
}
