//const $ = require('jquery');
declare var $: any;
const Mousetrap = require('../../vendor/mousetrap.js');

const platform = (typeof process != 'undefined' ? process.platform : navigator.platform)
const isMac = platform == 'darwin';




let domRoot: any = null;



function dropdownHover() {
    const activeMenu = $('#topMenu .active-container');
    if (activeMenu.length > 0) {
        const parent = $(this).parent();
        activeMenu.find('.d-menu').hide();
        activeMenu.find('a.dropdown-toggle').removeClass('active-control');
        activeMenu.removeClass('active-container')


        parent.find('a.dropdown-toggle').addClass('active-control');
        parent.addClass('active-container')
        parent.find('.d-menu').show();
    }

}

export function init(menu: any = {}) {
    console.log('init menu', menu);
    //handle menu mouse over
    $('#topMenu a.dropdown-toggle').on('mouseover', dropdownHover);
    domRoot = document.querySelector('#topMenu ul.menuRoot');



    for (let itemName in menu) {
        const item = createMenuItem(itemName, menu[itemName]);
        attacheMenuItem(item);
    }



    //adjust titlebar position
    const width: any = $(domRoot).width() - $('#winControls').width();
    const leftAdjust = parseInt(width) || 0;

    //$('#titleBar').css('left', `-${leftAdjust}px`);
}
export function addMenuItem(path: string, name: string, shortcut: string, handler?: any) {
    let itemParent = domRoot;
    for (let cur of path) {
        itemParent = itemParent.querySelector(`li[entry-name="${cur}"]`);
        if (!itemParent) return;
    }

    const item = { handler, shortcut };
    const entry = createMenuItem(name, item);
    attacheMenuItem(entry, itemParent);

}


function createMenuItem(name: string, entry: any) {
    let shortcut = '';
    name = entry.label || name;
    if (entry.shortcut) {
        const shortcutValue = isMac ? entry.shortcut.replace('Ctrl', 'Cmd') : entry.shortcut.replace('Cmd', 'Ctrl');
        shortcut = `<span class="shortcut">${shortcutValue}</span>`;
    }

    let children = null;

    if (entry.children) {
        children = $('<ul class="d-menu" data-role="dropdown"></ul>');
        for (let childName in entry.children) {
            const childEntry = entry.children[childName];
            const child = createMenuItem(childName, childEntry)
            children.append(child);

            if (childEntry.divide) {
                const divider = $('<li class="divider bg-lightGray">&nbsp;</li>');
                children.append(divider);
            }
        }
    }
    const a = $(`<a href="#">${name} ${shortcut}</a>`);
    if (entry.handler) {
        a.on('click', entry.handler);

        if (entry.shortcut) {
            const winShortcut = entry.shortcut.toLowerCase().replace('cmd', 'ctrl');
            const macShortcut = entry.shortcut.toLowerCase().replace('ctrl', 'command');

            Mousetrap.bind([macShortcut, winShortcut], entry.handler);
        }



    }

    const li = $(`<li entry-name="${name}"></li>`);

    li.append(a);
    if (children) li.append(children);

    return li;
}

function attacheMenuItem(item: any, parent?: any) {
    if (!parent) parent = domRoot;
    if (parent == domRoot) {
        //const firstA = item.find('a:first');
        const firstA = $(item[0].querySelector('a'));
        firstA.addClass('dropdown-toggle');
        firstA.on('mouseover', dropdownHover);
    }
    if (parent.tagName == 'LI') {
        parent = parent.querySelector('ul.d-menu');
    }

    $(parent).append(item);
}

