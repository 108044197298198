import RPC from './rpc';
import settings from './settings';
import api from './api';
import Browser from '../browser/Browser';

export default class User {
    static ticker = null;
    static refreshed = false;
    static async getProfile() {
        return RPC.auth.getProfile();
    }

    static async hideAll() {
        $('#profile-bar-top img.profile').hide();
        $('#profile-bar-top div.details').hide();
        $('#profile-bar-top div.actions').hide();
        $('#SignInBtn').hide();
        if (!Browser.isBrowser) {
            $('#browser-overlay').remove();
        }
        else {
            $('#signin-container').append($('#SignInBtn'));
            $('#browser-overlay').hide();
        }

    }
    static async hideProfile() {
        $('#profile-bar-top img.profile').hide();
        $('#profile-bar-top div.details').hide();
        $('#profile-bar-top div.actions').show();
        $('#SignInBtn').show();
        $('#browser-overlay').css('visibility', 'visible');
        $('#browser-overlay').show();

    }
    static async showProfile() {
        $('#profile-bar-top div.actions').hide();
        $('#profile-bar-top img.profile').show();
        $('#profile-bar-top div.details').show();
        $('#SignInBtn').hide();
        $('#browser-overlay').fadeOut(100);
    }

    static async setProfileInfo(profile, subscription) {
        $('#profile-bar-top img.profile').attr('src', profile.picture);
        $('#profile-bar-top h3.username').text(profile.name);

        this.setSubscriptionInfo(subscription.id, subscription.credits)
    }

    static async setSubscriptionInfo(plan_id, credits) {
        $('#profile-bar-top .subscription .text').text(plan_id);
        $('#profile-bar-top .credits .text').text(credits);

    }
    static async updateProfile() {
        this.hideAll();

        const profile = await this.getProfile();
        if (profile) {
            const userInfo: any = await api.get(`${settings.appSettings.serverUrl}/userinfo`);
            const plan: any = userInfo.plan;

            const curPeriod = plan && plan.end_period ? getRemainingTimeTo(plan.end_period) : null;
            const subscription = { ...plan, ...curPeriod }

            console.log('subscription info ', subscription);
            this.setProfileInfo(profile, subscription);



            //const subscription: any = await this.getUserInfo();




            this.showProfile();
        }
        else {
            this.hideProfile();
        }

        $('#SignInBtn').removeClass('ani-flash');


        if (!this.ticker) {
            const updateInterval = 10 * 60 * 1000;
            this.ticker = setInterval(() => {
                this.updateProfile();
            }, updateInterval);


            $('#SignInBtn').on('click', async () => {
                $('#SignInBtn').addClass('ani-flash');
                const profile = await RPC.auth.signIn();
                this.updateProfile();
            })
        }
    }
}



function getRemainingTimeTo(expiration) {
    const dateFuture: any = new Date(expiration);
    const dateNow: any = new Date();

    let seconds = Math.floor((dateFuture - (dateNow)) / 1000);
    if (seconds < 0) return { expired: true }
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    return { days, hours, minutes, seconds, expired: false };
}