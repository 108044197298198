import * as titlebar from './window/titlebar'
import RPC from './rpc'
import VoiceEditor from './editor/editor'
import { updateActorsList, updateContextualActors } from './editor/langbar'
import { updateFxList } from './editor/fxbar'
import AppSettings from './settings'
import { genID } from './editor/utils/ttstools';
import WaveSurfer from '../vendor/wavesurfer.js';
import SnackBar from './editor/utils/snackbar';
import User from './user';
import api from './api';
import { addGlobalCSS } from './editor/utils/style'

window.addGlobalCSS = addGlobalCSS;
window.RPC = RPC;
//const WaveSurfer = require('../vendor/wavesurfer.js');
//window['$'] = window['jQuery'] = require('jquery');
declare var $: any;
declare var Metro: any;
//declare var WaveSurfer: any;

AppSettings.userSettings.token = '8x3xC8oEYqCRZAH0kMlhDuGMqIfO80dh';
AppSettings.saveUserSettings();

window['VoiceEditor'] = VoiceEditor;
window['jQuery'] = $;


const delay = (ms) => new Promise(r => setTimeout(r, ms));
class App {
    static cmd = {
        newWindow: RPC.window.new,
        openFile: VoiceEditor.openFile,
        saveFile: VoiceEditor.saveFile,
        saveFileAs: VoiceEditor.saveFileAs,
        undo: VoiceEditor.undo,
        redo: VoiceEditor.redo

    }
    static async init() {
        console.log('App started');
        const loadAnim = Metro.activity.open({ type: 'bars', style: 'white', overlayAlpha: 1 })
        updateFxList();
        //load actors
        await updateActorsList();




        VoiceEditor.init();


        await VoiceEditor.ready();

        RPC.analytics.trackScreen('Main', 'TalkyStudio');
        titlebar.setTitle('[New]');

        User.updateProfile().catch(ex => console.log('User Profile error : ' + ex));


        window.wavesurfer = WaveSurfer.create({
            container: document.querySelector('#waveform'),
            barWidth: 2,
            responsive: true,
            backgroundColor: 'var(--txt-body-bg)',
            barHeight: 1, // the height of the wave
            barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy format,
            height: 60
        });

        $('#audioDlBtn').hide();
        $('#audioActivity').hide();

        $('#generateChunkBtn').on('mouseover', () => {
            const chunkId = $('#generateChunkBtn').attr('rel');
            const curChunk = document.getElementById(chunkId);

            if (curChunk) {
                const credits = VoiceEditor.calcCredits(curChunk);
                console.log('Req credits = ', credits);
                $('#generateChunkBtn').attr('data-hint-text', credits + ' credits');

                curChunk.parentElement.classList.add('highlighted');
            }
        })
        $('#generateChunkBtn').on('mouseout', () => {
            document.querySelectorAll('p.highlighted').forEach(node => node.classList.remove('highlighted'));
        });

        $('#generateChunkBtn').on('click', async () => {
            const profile = await User.getProfile();
            if (!profile) {
                App.showNotification('Oups!', "Please sign in first", 'error');
                return false;
            }
            const chunkId = $('#generateChunkBtn').attr('rel');
            const curChunk = document.getElementById(chunkId);
            if (!curChunk) return;

            if ($('#generateChunkBtn .icon').addClass('ani-flash'));
            VoiceEditor.generate(curChunk)
                .then(async (result) => {

                    $('#generateChunkBtn .icon').removeClass('ani-flash');
                    $('#generateBtn').show();
                    if (!result) {

                        App.showNotification('', "Nothing to synthesize", 'warning');
                        $('#generateChunkBtn .icon').removeClass('ani-flash');
                        return;

                    }
                    if (result.meta && result.meta.plan_id) {
                        const credits = result.meta.credits;
                        const plan_id = result.meta.plan_id;
                        User.setSubscriptionInfo(plan_id, credits);
                    }
                    if (wavesurfer) {
                        //wavesurfer._url = `${AppSettings.appSettings.filesEndpoint}` + result.audioFile;
                        //wavesurfer.load(wavesurfer._url);
                        wavesurfer._url = `${AppSettings.appSettings.filesEndpoint}` + result.audioFile;
                        const abuffer = await api.getArrayBuffer(wavesurfer._url);
                        wavesurfer.loadArrayBuffer(abuffer);

                    }
                    let warn = result.warn || '';
                    warn += result.parts.map(e => e.warn ? e.warn + '\n' : '').join(' ');
                    warn = warn.trim().replace(/\n/g, '<br />');
                    if (warn) App.showNotification('Warning', "Some operations failed : <br />" + warn, 'warning');
                    else App.showNotification('', "Audio created", 'success', 2500);
                })
                .catch(e => {
                    //Metro.toast.create("An error occured, please try again", null, 5000, "alert");
                    App.showNotification('Error', e.message, 'error');
                    setTimeout(() => {
                        App.showNotification('Oups!', "Speech synthesis failed<br />Please try again", 'error');

                    }, 500)

                    $('#generateChunkBtn .icon').removeClass('ani-flash');
                })
        });

        $('#generateBtn').on('mouseover', () => {

            const credits = VoiceEditor.calcCredits();


            //$('#generateBtn .badge').text('C x ' + credits)
            console.log('Req credits = ', credits);
            $('#generateBtn').attr('data-hint-text', credits + ' credits');


            document.querySelectorAll('.tts-chunk').forEach(node => node.parentElement.classList.add('highlighted'));

        })
        $('#generateBtn').on('mouseout', () => {
            document.querySelectorAll('p.highlighted').forEach(node => node.classList.remove('highlighted'));
        });

        $('#generateBtn').on('click', async () => {
            const profile = await User.getProfile();
            if (!profile) {
                App.showNotification('Oups!', "Please sign in first", 'error');
                return false;
            }

            if ($('#generateBtn .icon').hasClass('ani-flash')) return;


            $('#generateBtn .icon').addClass('ani-flash');
            $('#audioActivity').show();
            $('#audioPlayBtn').hide();
            wavesurfer.empty()
            wavesurfer._url = null;


            VoiceEditor.generate()
                .then(async (result) => {
                    if (!result) {
                        App.showNotification('', "Nothing to synthesize", 'warning');
                        $('#generateBtn .icon').removeClass('ani-flash');
                        $('#audioActivity').hide();
                        $('#audioPlayBtn').hide();
                        return;
                    }
                    if (result.meta && result.meta.plan_id) {
                        const credits = result.meta.credits;
                        const plan_id = result.meta.plan_id;
                        User.setSubscriptionInfo(plan_id, credits);
                    }
                    if (wavesurfer) {

                        //wavesurfer.load(wavesurfer._url);

                        wavesurfer._url = `${AppSettings.appSettings.filesEndpoint}` + result.audioFile;
                        const abuffer = await api.getArrayBuffer(wavesurfer._url);
                        wavesurfer.loadArrayBuffer(abuffer);
                    }

                    let warn = result.warn || '';
                    warn += result.parts.map(e => e.warn ? e.warn + '\n' : '').join(' ');
                    warn = warn.trim().replace(/\n/g, '<br />');
                    //if (warn) Metro.toast.create("Some operations failed : <br />" + warn, null, 5000, "warning");
                    if (warn) App.showNotification('Warning', "Some operations failed : <br />" + warn, 'warning');
                    else App.showNotification('', "Audio created", 'success', 2500);
                })
                .catch(e => {
                    console.error(e);


                    App.showNotification('Error', e.message, 'error');
                    setTimeout(() => {
                        App.showNotification('Oups!', "Speech synthesis failed<br />Please try again", 'error');
                    }, 500)

                    $('#generateBtn .icon').removeClass('ani-flash');
                    $('#audioActivity').hide();
                    $('#audioPlayBtn').hide();
                })
        });

        $('#audioDlBtn').on('click', () => {

            console.log('downloading', wavesurfer._url);
            if (wavesurfer._url) {
                RPC.window.download(wavesurfer._url);
            }
        })


        $('#audioPlayBtn').hide();
        wavesurfer.on('ready', () => {
            $('#audioPlayBtn').show();
            $('#audioPlayBtn span').addClass('mif-pause');
            $('#audioPlayBtn span').removeClass('mif-play');

            $('#generateBtn .icon').removeClass('ani-flash');
            //$('#generateBtn').hide();
            $('#audioActivity').hide();
            $('#audioDlBtn').show();
            wavesurfer.play()
        })
        wavesurfer.on('finish', () => {
            $('#audioPlayBtn span').removeClass('mif-pause');
            $('#audioPlayBtn span').addClass('mif-play');
        })
        $('#audioPlayBtn').on('click', () => {
            if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
                $('#audioPlayBtn span').removeClass('mif-pause');
                $('#audioPlayBtn span').addClass('mif-play');
            }
            else {
                wavesurfer.play();
                $('#audioPlayBtn span').removeClass('mif-play');
                $('#audioPlayBtn span').addClass('mif-pause');
            }
        })






        //VoiceEditor.setEndPoint('https://api.eu-1.talky.studio');//'http://localhost:3001'

        $('#wraper').css('position', 'unset');
        $('#overlay').fadeOut();
        Metro.activity.close(loadAnim);
    }


    static showNotification(title, message, cls, timeout = 10000) {

        new SnackBar({
            message: title ? `<b>${title}</b><br/>${message}` : message,
            width: '184px',
            status: cls,
            timeout
        });
    }


    static highlightShow(selector) {

        $('.highlight-z0').css('z-index', '0');
        $('#overlay').fadeTo(200, 0.85, () => { });
        $(selector).addClass('highlight');
    }
    static highlightHide() {

        $('#overlay').fadeOut(200, () => {
            $('.highlight').removeClass('highlight');
            $('.highlight-z0').css('z-index', '1');
        });
    }

    /**
     * this function is called when new content is loaded.
     * it can be used for multi windows applications, in this case, the newly opened window receives the content to load
     * @param message 
     */
    static loadContent(message: any) {
        console.log('loadContent', message);
        VoiceEditor.loadData(message);
    }

    static openFile = async function () {
        {
            const file = await RPC.menu.file.open(true);
            console.log('opened file', file);
        }
    }


    static promptClose() {
        return new Promise(resolve => {
            if (!VoiceEditor.file.changed) return resolve(true);

            Metro.dialog.create({
                title: "Unsaved changes",
                content: "<div>You have unsaved changes. <br />Do you want to discard or save them?</div>",
                overlayAlpha: 0.7,
                clsDialog: 'dlg-prompt',
                actions: [
                    {
                        caption: "Cancel",
                        cls: "js-dialog-close warning float-left",
                        onclick: () => resolve(false)
                    },
                    {
                        caption: "Discard",
                        cls: "js-dialog-close alert outline",
                        onclick: () => resolve(true)
                    },
                    {
                        caption: "Save",
                        cls: "js-dialog-close success",
                        onclick: async function () {
                            const saved = await VoiceEditor.saveFile();
                            if (saved) resolve(true)
                        }
                    }

                ]
            });
        })

    }

}

RPC.events.on('contentLoaded', App.loadContent);

VoiceEditor.on('textlang', updateContextualActors);

VoiceEditor.on('error', msg => {
    App.showNotification('Error', msg, 'error', -1);
})

VoiceEditor.on('chunkClick', (chunk) => {


    chunk.id = genID(); //ensure unique id
    $('#generateChunkBtn').attr('rel', chunk.id)

    const pos = $(chunk).parent().position();
    var div = document.querySelector('.sun-editor-editable');
    $('#generateChunkBtn').css({ left: `2px`, top: `${pos.top + 74 - div.scrollTop}px` });

    $('#generateChunkBtn').fadeIn('fast');

});

window.App = App;

// document.addEventListener('wheel', () => {
//     //     //$('#generateChunkBtn').hide();
//     const chunk = document.getElementById($('#generateChunkBtn').attr('rel'));
//     const genBtn = document.querySelector('#generateChunkBtn');
//     var div = document.querySelector('.sun-editor-editable');
//     genBtn.style.top = (chunk.offsetTop - div.scrollTop + 74) + 'px'
// });


export default App;