export function setTitle(title: string) {

    const titlebar: HTMLElement = document.querySelector('#titleBar');
    titlebar.classList.add('updating');

    setTimeout(() => {
        titlebar.innerText = title;
        titlebar.classList.remove('updating');
    }, 110)





}

export function setChanged(changed: boolean) {
    const titlebar = document.querySelector('#titleBar');
    if (!changed) {
        titlebar.classList.remove('changed')
    }
    else {
        if (!titlebar.classList.contains('changed'))
            titlebar.classList.add('changed')
    }
}
