


import { updateSSML } from '../utils/ttstools'

const tagName = 'emphasis';
const attributes = ['data-label', 'level'];
const commandName = `ssml-${tagName}`;


export default {
    name: commandName,
    display: 'command',
    innerHTML: '<i class="mif-plus"></i>',
    buttonClass: 'button tbtn-emphasis',
    title: 'Emphasis',

    add: function (core, targetElement) {
        const context = core.context;
        const span = document.createElement('span')
        context[commandName] = {
            targetButton: targetElement,
            targetText: span
        };



        span.className = 'txt';
        span.innerText = `[${tagName}]`;
        targetElement.appendChild(span);

    },
    active: function (element) {
        const nodeSpeech = VoiceEditor.getNodeSpeech();
        if (!element) {
            this.util.changeTxt(this.context[commandName].targetText, `[${tagName}]`);
            this.util.removeClass(this.context[commandName].targetButton, 'active');
        } else if (nodeSpeech && nodeSpeech.emphasis) {
            this.util.changeTxt(this.context[commandName].targetText, element.getAttribute(tagName));
            this.util.addClass(this.context[commandName].targetButton, 'active');
            return true;
        }

        return false;
    },
    action: function () {
        //if (!/^BUTTON$/i.test(e.target.tagName)) return false;
        let curRange = this.getRange();
        if (curRange.collapsed) {
            if (!curRange.startContainer.parentElement.innerText.trim()) return;
            $(curRange.startContainer.parentElement).selectText();
            curRange = this.getRange();
        }

        openEmphasisDialog();





    }
}

function openEmphasisDialog() {

    const actions = [];
    const vList = [
        { text: 'Strong', value: 'strong' },
        { text: 'Moderate', value: 'moderate' },
        { text: 'Reduced', value: 'reduced' },
        { text: 'Clear', value: '', cls: 'alert' },
    ];

    for (let opt of vList) {

        const action = {
            caption: opt.text,
            cls: "js-dialog-close button success outline btn-dlg tooltip " + (opt.cls || ''),
            onclick: () => setEmphasisLevel(opt.value)
        }
        actions.push(action);
    }


    const dialog = Metro.dialog.create({
        title: "Emphasis level",
        content: "",
        clsDialog: 'emphasis-modal toolbar-dlg',
        overlayClickClose: true,
        overlayAlpha: 0.1,
        actions
    });

    const curRange = VoiceEditor.sunEditor.core.getRange()
    if (!curRange.collapsed) {
        const brect = curRange.getBoundingClientRect();
        const dlgWidth = dialog.width();
        dialog.offset({ top: brect.y + 30, left: Math.min(brect.x, window.innerWidth - dlgWidth) });
    }

}

function setEmphasisLevel(level) {
    //VoiceEditor.sunEditor.core.history.push();
    //VoiceEditor.sunEditor.core.history._lock = true;
    VoiceEditor.setNode({ emphasis: level })
    //VoiceEditor.sunEditor.core.history._lock = false;
    updateSSML(tagName);
    //VoiceEditor.sunEditor.core.history.push();
}

