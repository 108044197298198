let styleElement = null;
export function addGlobalCSS(selector, style) {
    if (!selector || !style) return;
    if (!styleElement) {
        styleElement = document.createElement('style');
        document.head.appendChild(styleElement);
    }


    const styleString = (
        Object.entries(style).map(([k, v]) => `${k}:${v}`).join(';')
    );
    const rule = `${selector} {${styleString}}`;
    styleElement.sheet.insertRule(rule);
}