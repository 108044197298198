import AppSettings from '../settings'
import api from '../api'
declare var Metro: any;
declare var VoiceEditor: any;

function createFxTile(fx: any) {
    let imgUrl = '';
    if (fx.img) imgUrl = `./img/${fx.img}`;
    const style = imgUrl ? `background-image:url(${imgUrl});` : '';
    let favbox = `
      <div class="favbox">
        <div class="fav-btn"><span class="favme mif-heart"></span></div>        
      </div>`;

    if (!fx.name) favbox = '';

    const tile = $(`
    <div class="tile fx" data-fx="${fx.name}" >
        ${favbox}
        <a href="#" data-fx="${fx.name}" style="${style}">
        <span class="badge-fx">${fx.label || fx.name}</span>                        
        </a>
    </div>`);


    tile.find('a').on('click', function () {
        if (typeof fx.action == 'function') return fx.action(this);

        VoiceEditor.setFx($(this).attr('data-fx'));
    });

    // Favorite Button - Heart
    tile.find('.favme').on('click', function () {
        $(this).toggleClass('active');
    });

    /* when a user clicks, toggle the 'is-animating' class */
    tile.find(".favme").on('click touchstart', function () {
        $(this).toggleClass('is_animating');
    });

    /*when the animation is over, remove the class*/
    tile.find(".favme").on('animationend', function () {
        $(this).toggleClass('is_animating');
    });
    return tile;
}



export function updateFxList() {

    $('#inside-fx-bar .contextual').html('');
    $('#inside-fx-bar .all').html('');

    const tile = createFxTile({ name: '', label: 'Clear Fx', img: './icon/clear.png', action: clearFx });
    const empty = createFxTile({ name: '', label: 'Prosody', img: './icon/prosody.png', action: advProsody });
    $('#top-fx-bar .top').append(tile);
    $('#top-fx-bar .top').append(empty);

    loadFxList().then(fxList => {
        fxList.forEach(fx => {
            const tile = createFxTile(fx);
            $('#inside-fx-bar .all').append(tile);

        })
    })





}


async function loadFxList() {

    const reqBody = {};
    const fxList = await api.post(AppSettings.appSettings.fxListEndpoint, reqBody).catch(error => {
        console.error(error);
    });


    return fxList;
}

function clearFx(target) {
    console.log('clearing FX ', target);
    VoiceEditor.setFx($(target).attr('data-fx'));

    const targets = VoiceEditor.getSelectedElements();
    for (let target of targets) {
        target.querySelectorAll('.tts-chunk').forEach(item => {
            item.removeAttribute('data-dur');
            item.removeAttribute('data-pitch');
            item.classList.remove('adv-prosody');

        })
    }
}


//Advanced prosody FX
let curChunk;
function advProsody() {
    const chunkId = $('#generateChunkBtn').attr('rel');


    curChunk = document.getElementById(chunkId);
    const durData = curChunk.getAttribute('data-dur');
    const pitchData = curChunk.getAttribute('data-pitch');

    $('#CSContainer').addClass('loading');
    $('.prosody-editor-dlg .loader').addClass('active');

    Metro.dialog.open('#prosody-editor');
    VoiceEditor.generate(curChunk)
        .then(async (result) => {
            const partId = result.parts[0].id;
            await VoiceEditor.prosodyEditor.load(partId, pitchData, durData)
                .catch(err => {
                    VoiceEditor.trigger('error', 'Failed to load audio data');
                    Metro.dialog.close('#prosody-editor');
                });

            $('.prosody-editor-dlg .loader').removeClass('active');
            $('#CSContainer').removeClass('loading');

        })
        .catch(e => {
            Metro.toast.create("An error occured, please try again", null, 5000, "alert");

        });

}
window['prosodyEditorActions'] = [
    {
        caption: "Preview",
        cls: "btn-play button success outline tooltip float-left",
        onclick: async () => {
            VoiceEditor.prosodyEditor.wavesurfer.stop();
            if (!VoiceEditor.prosodyEditor._changed) {
                VoiceEditor.prosodyEditor.wavesurfer.play();
                return;
            }

            VoiceEditor.prosodyEditor.needPlay = true;
            const pitchDurData = await VoiceEditor.prosodyEditor.updateSound();
            VoiceEditor.prosodyEditor.postData(pitchDurData);

        }
    },
    {
        caption: "Reset",
        cls: "btn-reset button alert outline tooltip float-left",
        onclick: () => {
            VoiceEditor.prosodyEditor.reset();
        }
    },
    {
        caption: "Cancel",
        cls: "btn-cancel js-dialog-close button warning outline tooltip",
        onclick: () => {
        }
    },
    {
        caption: "Save & Close",
        cls: "btn-ok js-dialog-close button success outline tooltip",
        onclick: () => {
            const pitchData = VoiceEditor.prosodyEditor.getPitchData();
            const durData = VoiceEditor.prosodyEditor.getDurData();
            curChunk.setAttribute('data-pitch', pitchData);
            curChunk.setAttribute('data-dur', durData);
            curChunk.classList.add('adv-prosody');

            VoiceEditor.prosodyEditor.destroy();
            $('.prosody-editor-dlg .loader').addClass('active');
        }
    }

];