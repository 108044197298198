import Prosody from './prosody'


const volume = new Prosody({
    control: 'volume',
    icon: 'mif-volume-high',
    title: 'Volume',
    name: 'ssml-volume',
    cssClass: 'tbtn-volume'
});

const plugin = volume.plugin;


export default plugin;