import * as frame from './window/frame'
import * as topMenu from './window/topmenu'
import * as titlebar from './window/titlebar'

import { menu } from './menu.config';
import App from './App';



(async () => {
    frame.init({ onClose: App.promptClose });

    setTimeout(() => $('#overlay .top').fadeOut(200), 500);


    await App.init();
    topMenu.init(menu);
})();