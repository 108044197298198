import BrowserIPC from '../browser/BrowserIPC'
import { config } from '../app.config'

let auth0;
async function getAuth0() {
    if (!auth0) {
        return createAuth0Client({
            domain: config.auth0.auth0Domain,
            client_id: config.auth0.webClientId,
            cacheLocation: 'localstorage'
        });
    }

    return new Promise(resolve => resolve(auth0));
}

BrowserIPC.handle('auth:getProfile', async () => {
    console.log('getProfile from BrowserRPC');
    auth0 = await getAuth0();



    const profile = await auth0.getUser();
    if (!profile) return null;
    const accessToken = await auth0.getTokenSilently({
        audience: config.auth0.apiIdentifier,
        scope: 'openid profile offline_access app_metadata user_metadata'
    });

    profile.accessToken = accessToken;
    return profile;
});

BrowserIPC.handle('auth:signIn', async () => {
    auth0 = await getAuth0();
    await auth0.loginWithRedirect({
        audience: config.auth0.apiIdentifier,
        scope: 'openid profile offline_access app_metadata user_metadata',
        redirect_uri: 'http://localhost:1234'
    });
    const profile = await auth0.getUser();

    console.log('Signing in profile', profile);
    if (!profile) return null;


    if (!profile.accessToken) {
        const accessToken = await auth0.getTokenSilently({
            audience: config.auth0.apiIdentifier,
            scope: 'openid profile offline_access app_metadata user_metadata'
        });
        profile.accessToken = accessToken;
    }


    return profile;
});

BrowserIPC.handle('auth:signOut', async () => {
    auth0 = await getAuth0();
    auth0.logout();

    /*
    await auth0.logout({
        returnTo: 'http://localhost:1234/'
    });
    */
    return;
});



BrowserIPC.handle('menu:save', async (fileContent, filePath) => {
    console.log(fileContent, filePath);
    var bb = new Blob([fileContent], { type: 'text/plain' });
    var a = document.createElement('a');
    a.download = 'file.tlk';
    a.href = window.URL.createObjectURL(bb);
    a.click();
})
export default class BrowserRPC {
    static init() { };
}