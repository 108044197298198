import VoiceEditor from '../editor';
import { updateSSML } from '../utils/ttstools'

const tagName = 'break';
const commandName = `ssml-${tagName}`;
let lastActiveElement;
export default {
    name: commandName,
    display: 'command',
    innerHTML: '<i class="mif-pause"></i>',
    buttonClass: 'button tbtn-break',
    title: 'Break',

    add: function (core, targetElement) {
        const context = core.context;
        const span = document.createElement('span')
        context[commandName] = {
            targetButton: targetElement,
            targetText: span
        };

        span.className = 'txt';
        span.innerText = `[${tagName}]`;
        targetElement.appendChild(span);
    },
    active: function (element) {
        var regex = new RegExp(`^${tagName}$`, 'i')
        if (!element) {
            this.util.removeClass(this.context[commandName].targetButton, 'active');
            if (lastActiveElement) lastActiveElement.classList.remove('active');
        } else if (regex.test(element.nodeName)) {
            this.util.addClass(this.context[commandName].targetButton, 'active');
            if (lastActiveElement) lastActiveElement.classList.remove('active');
            element.classList.add('active');

            lastActiveElement = element;
            return true;
        }

        return false;
    },
    action: function () {
        //if (!/^BUTTON$/i.test(e.target.tagName)) return false;
        let curRange = this.getRange();
        if (!curRange.collapsed) return;

        openBreakDialog();

    }
}

const dialogContent = `
<form>
    <div class="form-group prosody-slider">
    <label>Time (ms)</label>
    <div id="break-time-slider" data-name="time" data-value="200" data-min="0" data-max="5000"
        data-role="slider" data-accuracy="10" data-hint-always="true" data-hint-position="top"></div>
    </div>
</form>
`

function openBreakDialog() {

    const actions = [
        {
            caption: "",
            cls: "btn-ok js-dialog-close button success cycle outline btn-dlg tooltip",
            onclick: applyChanges.bind(VoiceEditor.sunEditor.core)
        },
        {
            caption: "",
            cls: "btn-cancel js-dialog-close button alert cycle outline btn-dlg tooltip",
            onclick: () => {
                console.log('cancel');
            }
        },
        {
            caption: "",
            cls: "btn-clr js-dialog-close button alert cycle btn-dlg tooltip",
            onclick: removeBreak.bind(VoiceEditor.sunEditor.core)
        }

    ];

    let activeContainer;


    const onDialogCreate = () => {


        $('.break-modal button.btn-cancel').html(`<span class="mif-cross icon fg-orange"></span><span class="tooltiptext">Cancel changes</span>`)
        $('.break-modal button.btn-ok').html(`<span class="mif-checkmark icon fg-green"></span><span class="tooltiptext">Apply changes</span>`)
        $('.break-modal button.btn-clr').html(`<i class="fas fa-eraser"></i><span class="tooltiptext">Remove break</span>`)

    }

    const dialog = Metro.dialog.create({
        title: '',
        content: dialogContent,
        clsDialog: 'break-modal toolbar-dlg',
        overlayClickClose: true,
        overlayAlpha: 0.1,
        onDialogCreate,
        actions
    });



    const curRange = VoiceEditor.sunEditor.core.getRange()

    if (curRange.startContainer.nodeName == tagName.toUpperCase())
        activeContainer = curRange.startContainer;
    if (!activeContainer && curRange.startContainer.parentElement.nodeName == tagName.toUpperCase())
        activeContainer = curRange.startContainer.parentElement;

    if (curRange.collapsed) {
        const brect = curRange.getBoundingClientRect();
        const dlgWidth = dialog.width();
        dialog.offset({ top: brect.y + 30, left: Math.min(brect.x, window.innerWidth - dlgWidth) + (activeContainer ? 10 : 0) });
    }

    if (activeContainer) {
        setTimeout(() => {
            const slider = Metro.getPlugin(`#break-time-slider`, 'slider');
            console.log(slider);
            const curVal = parseInt(activeContainer.getAttribute('time')) || 200;

            slider.val(curVal);
        }, 20)
    }

}

function removeBreak() {
    const curRange = this.getRange();
    if (!curRange.collapsed) return false;

    const slider = Metro.getPlugin(`#break-time-slider`, 'slider');

    this.history.push();
    this.history._lock = true;


    if (curRange.startContainer.nodeName == tagName.toUpperCase())
        curRange.startContainer.remove();

    if (curRange.startContainer.parentElement.nodeName == tagName.toUpperCase())
        curRange.startContainer.parentElement.remove();
    this.removeRange();


    this.history._lock = false;
    updateSSML();


    this.history.push();
}


function applyChanges() {
    const curRange = this.getRange();
    if (!curRange.collapsed) return false;

    const slider = Metro.getPlugin(`#break-time-slider`, 'slider');

    console.log('Apply break changes');
    this.history.push();
    this.history._lock = true;

    let activeContainer;
    if (curRange.startContainer.nodeName == tagName.toUpperCase())
        activeContainer = curRange.startContainer;
    if (!activeContainer && curRange.startContainer.parentElement.nodeName == tagName.toUpperCase())
        activeContainer = curRange.startContainer.parentElement;

    if (activeContainer) {
        activeContainer.setAttribute('time', slider.val());
        this.removeRange();
    }
    else {
        const newNode = this.util.createElement(tagName);


        newNode.setAttribute('time', slider.val());
        newNode.setAttribute('data-label', tagName);

        newNode.className = `SSML ${commandName}`;
        //this.nodeChange(newNode, ['.SSML', `.${commandName}`], null, false);
        curRange.insertNode(newNode);

        newNode.innerHTML = '&nbsp;'
        //newNode.after(document.createTextNode("&nbsp;"));


        this.removeRange();


    }

    this.history._lock = false;
    updateSSML();


    this.history.push();

}