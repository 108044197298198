import * as NLP from './nlp';
import LZString from 'lz-string';
const { xml2js } = require('xml-js');

export const settings = {
    SPLIT_SENTENCES: false,
    curVoice: 'Akon-en-GB',
    voiceList: {

    },

    stylesList: [
        { element: { "background-color": '#4b417b87 !important' } },
        { element: { "background-color": '#00808085 !important;' }, "::before": { color: 'white !important' } },
        { element: { "background-color": '#80800085 !important;color:yellow !important' } }
    ],
    styleIdx: 1,
    voiceStyles: {
        'Akon-en-GB': 0
    }

}



const _hashTable = {}


const emptyTags = ['breath', 'break'];




export const flattenSSMLNode = (pnode: any) => {
    const childNodes = [...pnode.childNodes];
    for (let n of childNodes) {
        if (n.nodeType == 3 || n.tagName != pnode.tagName) {
            const clone = pnode.cloneNode();
            n.after(clone);
            clone.appendChild(n);
            if (n.children && n.children.length == 1) {
                const gchild = n.children[0];
                [...gchild.attributes].forEach(a => clone.setAttribute(a.name, a.value));
                gchild.outerHTML = gchild.innerHTML;
            }
        }
    }

    pnode.outerHTML = pnode.innerHTML;
}


export const updateSSML = () => {
    return updateTTSChunks();
}
export function getVoiceLabel(voiceId) {
    let label = voiceId || settings.curVoice;
    const voice = settings.voiceList[voiceId];

    if (voice && (voice.displayRTL || voice.displayLTR)) {
        label = voice.isRTL ? (voice.displayRTL || voice.displayLTR || voiceId) : (voice.displayLTR || voiceId)
    }
    return label;

}
export const updateTTSChunks = (afterPast = false) => {
    document.querySelectorAll('.se-container p>breath').forEach(e => e.remove());
    document.querySelectorAll('.se-container p>break').forEach(e => e.remove());

    document.querySelectorAll('.se-container span breath').forEach(e => e.innerHTML = '&nbsp;');
    document.querySelectorAll('.se-container span break').forEach(e => e.innerHTML = '&nbsp;');


    document.querySelectorAll('.se-container p [class*="ssml-"]').forEach((e: HTMLElement) => {

        if (!e.classList.contains('SSML')) e.classList.add('SSML');

        //next we will clean up empty tags

        //first ignore tags that should be empty
        if (!emptyTags.contains(e.nodeName.toLowerCase())) {

            if (e.innerText == '') { //remove empty tags
                console.warn('removing empty tag ', e)
                e.remove();
            }
        }

    });


    //split sentences in paragraphs without spans
    [...document.querySelectorAll(".sun-editor-editable p")]
        .filter(p => !p.querySelector('span'))
        .forEach(e => {

            if (settings.SPLIT_SENTENCES) {
                const sentences = NLP.splitSentences(e.innerHTML);

                e.innerHTML = '';
                for (let sent of sentences) {


                    e.innerHTML += `<span id="${genID()}" class="tts-chunk" >${sent.text.replace(/\s+/g, ' ')} </span>`;
                }
            }
            else {
                e.innerHTML = `<span id="${genID()}" class="tts-chunk" >${e.innerHTML} </span>`;
            }

        });


    //all spans should have tts-chunk class
    [...document.querySelectorAll('.sun-editor-editable p > span')].filter(s => !s.classList.contains('tts-chunk')).forEach(s => s.classList.add('tts-chunk'));

    //split sentences inside spans (this can occure after edited span)
    if (settings.SPLIT_SENTENCES) {
        [...document.querySelectorAll(".sun-editor-editable p span")]
            .filter((span: HTMLElement) => span.innerText.replace(/\s+/g, ' ') == span.innerHTML.replace(/\s+/g, ' ') && /[\.!?:;].+/g.test(span.innerText.trim()))
            .forEach(e => {
                const sentences = NLP.splitSentences(e.innerHTML);
                let outerHTML = '';
                let className = e.className;
                if (className.indexOf('tts-chunk') < 0) className = 'tts-chunk ' + className;


                const voiceId = e.getAttribute('voice');
                //const voice = settings.voiceList[voiceId];


                for (let sent of sentences) {

                    const label = getVoiceLabel(voiceId);
                    outerHTML += `<span id="${genID()}" class="${className}" voice="${voiceId}"  data-label="${label}" >${sent.text.replace(/\s+/g, ' ')} </span>`;
                }
                e.outerHTML = outerHTML;

            });
    }


    //sometimes, copy pasting may result in spans with styling or with empty child spans
    document.querySelectorAll('.sun-editor-editable p > span').forEach(span => {

        span.removeAttribute('style'); //remove styles


        // remove empty child spans
        span.querySelectorAll('span').forEach(childSpan => {
            if (childSpan.innerText.trim() == '') childSpan.remove();
        })
    });



    //remove empty chunks
    document.querySelectorAll('.sun-editor-editable .tts-chunk').forEach(e => {
        const content = e.innerHTML.trim();

        switch (content) {
            case '':
                e.remove()
                break;
            case '<br>':
            case '<br />':
                e.innerHTML = "&nbsp;"
                break;
            default:
                if (!afterPast) e.innerHTML = normalizeHTMLSpaces(e.innerHTML)
        }

    });


    //[...document.querySelectorAll('.sun-editor-editable .tts-chunk')].filter(e => e.innerHTML.trim() == '<br>').forEach(e => e.innerHTML = "&nbsp;");


    document.querySelectorAll(".sun-editor-editable p").forEach((p: HTMLElement) => mergeSpans(p));


    //after merging, remove remaining nested spans
    document.querySelectorAll('.sun-editor-editable p > span span').forEach(span => span.outerHTML = span.innerHTML)





    updateTextLang();



    setDefaultVoice();
}

export function prepareOptimizedTTS(span: HTMLElement) {
    var sclone: HTMLElement = <HTMLElement>span.cloneNode(true)
    var parent = document.createElement('p');
    parent.appendChild(sclone);
    sclone.querySelectorAll('tts').forEach((tts: HTMLElement) => {
        const str = tts.innerHTML.replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').replace(/\s+,/g, ',');
        //const arr = NLP.splitAfter(str, '@hasComma');
        const arr = NLP.splitSentences(str);

        if (arr.length > 1) {
            const ttsOpen: HTMLElement = <HTMLElement>tts.cloneNode().outerHTML.replace('</tts>', '')
            const spanOpen: HTMLElement = <HTMLElement>sclone.cloneNode().outerHTML.replace('</span>', '').replace('<', '#{{').replace('>', 'inline="1"}}#');
            //console.log(ttsClone.outerHTML.replace('</tts>', ''))

            var res = arr.map((e, i) => i < arr.length - 1 ? [e, '</tts>', '#{{/span}}#', spanOpen, ttsOpen] : [e]).reduce((a, b) => a.concat(b))
            res.unshift(ttsOpen);
            res.push('</tts>')
            tts.outerHTML = res.join('');

        }

    });

    sclone.childNodes.forEach((node: HTMLElement) => {
        if (node.nodeName !== '#text' || node.parentElement.nodeName == 'TTS') return;

        const str = node.textContent.replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').replace(/\s+,/g, ',');
        //const arr = NLP.splitAfter(str, '@hasComma');
        const arr = NLP.splitSentences(str);
        if (arr.length > 1) {
            const spanOpen = sclone.cloneNode().outerHTML.replace('</span>', '').replace('<', '#{{').replace('>', 'inline="1"}}#');

            var res = arr.map((e, i) => i < arr.length - 1 ? [e, '#{{/span}}#', spanOpen] : [e]).reduce((a, b) => a.concat(b))
            node.textContent = res.join('');

        }

    });


    sclone.outerHTML = sclone.outerHTML.replace(/#{{(span[^}]+)}}#/g, '<$1>').replace(/#{{\/span}}#/g, '</span>')
    return parent;
}

// export function getProsodyInterpData(str) {
//     const data = LZString.decompressFromEncodedURIComponent(str);
//     if (!data) return null;
//     const tokens = data.split(':');
//     return JSON.parse(tokens[1]);
// }
export function getProsodyEditData(str) {
    const data = LZString.decompressFromEncodedURIComponent(str);
    if (!data) return null;
    const tokens = data.split(':'); //in case of multiple encoded data, first one contains editor data
    return JSON.parse(tokens[0]);
}
function getEltLastLeaf(node) {
    let lastLeaf = null;
    let elements = node.elements;
    if (node.data && node.data.elements) elements = node.data.elements;

    if (elements) {
        for (let child of elements) {
            lastLeaf = getEltLastLeaf(child);
        }
    }
    else {
        return node;
    }
    return lastLeaf;
}

export function prepareTTSChunk(span: HTMLElement) {
    let result = [];
    if (!span.innerText.trim()) return result;
    if (!span.id) span.id = genID();

    const clone = prepareOptimizedTTS(span);
    clone.querySelectorAll('.tts-chunk').forEach((span: HTMLElement) => {
        let html = span.innerHTML.trim().replace(/<\/?br [^>]*>/ig, ' ').replace(/<\/?br\s*\/?>/ig, ' ');
        html = html.replace(/(&nbsp;|\s)+<breath/g, '<breath').replace(/<\/breath([^>]*>)(&nbsp;|\s)+/g, '</breath$1'); //normalize spaces before and after breath node.
        html = html.replace(/(&nbsp;|\s)+<break/g, '<break').replace(/<\/break([^>]*>)(&nbsp;|\s)+/g, '</break$1'); //normalize spaces before and after break node.

        //const pitch = getProsodyInterpData(span.getAttribute('data-pitch'));
        //const dur = getProsodyInterpData(span.getAttribute('data-dur'));

        const pitchData = getProsodyEditData(span.getAttribute('data-pitch'));
        const durData = getProsodyEditData(span.getAttribute('data-dur'));

        const data = ssml2json(`<speak>${html}</speak>`);

        result.push({ id: span.id, fx: span.getAttribute('fx'), pitchData, durData, voice: span.getAttribute('voice'), data });

        let lastLeaf = getEltLastLeaf(data);


        if (lastLeaf.name == 'break') { //explicit break element
            const timeVal = lastLeaf.attributes && lastLeaf.attributes.time ? parseInt(lastLeaf.attributes.time) || 200 : 200;
            const delay = timeVal / 1000;
            result.push({ id: span.id, voice: 'FX', data: { s: delay } });
        }
        else {

            if (span.innerText.trim().match(/[.!?:]$/)) { //text ends with fullstop 

                const delay = span.nextSibling && span.nextSibling.hasAttribute('inline') ? 0.4 : 0.8;
                result.push({ id: span.id, voice: 'FX', data: { s: delay } });
            }

            if (span.innerText.trim().match(/[,]$/)) { //text ends with comma 

                const delay = span.nextSibling && span.nextSibling.hasAttribute('inline') ? 0.1 : 0.3;
                result.push({ id: span.id, voice: 'FX', data: { s: delay } });
            }
        }

    })

    return result;
}
export const prepareTTSRequest = (updateChunks = true) => {
    if (updateChunks) updateTTSChunks();

    let result: any[] = [];

    document.querySelectorAll(".sun-editor-editable .tts-chunk").forEach((span: HTMLElement) => {
        const chunks = prepareTTSChunk(span);
        result = result.concat(chunks);
    });

    return result;
}

export const setSelectedVoice = (voice = 'Aria') => {
    settings.curVoice = voice;
    console.log('setVoice', voice);
}

function setDefaultVoice() {
    document.querySelectorAll(".sun-editor-editable span.tts-chunk").forEach(span => {
        if (!span.hasAttribute('voice') || span.getAttribute('voice').trim() == '') {
            span.setAttribute('voice', settings.curVoice);
            span.className = 'tts-chunk ' + 'v-' + settings.curVoice;
        }
    })
}
export function genID() {
    return '_' + Math.random().toString(36).substr(2, 9);
};




export function updateTextLang() {
    document.querySelectorAll(".sun-editor-editable p").forEach(async (p: HTMLElement) => {
        const lang = await NLP.lang(p.innerText);
        if (lang == 'ar' || lang == 'he') {
            p.classList.add('rtl');
        }
        else {
            p.classList.remove('rtl');
        }

        p.querySelectorAll('.tts-chunk').forEach(async (element: HTMLElement) => {
            let lang = await NLP.lang(element.innerText);
            const voiceId = element.getAttribute('voice');
            const voice = settings.voiceList[voiceId];

            let rtl = (lang == 'ar' || lang == 'he');
            if (voice && lang == 'unknown') {
                rtl = voice.isRTL || rtl;
                if (voice.isRTL) p.classList.add('rtl');
            }

            element.setAttribute('lang', lang);


            if (voice && rtl) {
                element.setAttribute('data-label', voice.displayRTL || voiceId);
            }
            else {

                element.setAttribute('data-label', voice && voice.displayLTR ? voice.displayLTR : voiceId);
            }
        });



    });
}

function mergeSpans(para: HTMLElement) {

    para.removeAttribute('style'); //remove styles
    var curSpan: HTMLElement = null;

    //first merge text nodes to spans
    [...para.childNodes].filter(c => c.nodeType === 3).forEach((c: HTMLElement) => {
        let html = '';
        if (c.previousSibling) html += c.previousSibling.nodeType === 3 ? c.previousSibling.textContent : c.previousSibling.innerHTML;
        html += c.textContent;
        if (c.nextSibling) html += c.nextSibling.nodeType === 3 ? c.nextSibling.textContent : c.nextSibling.innerHTML;

        if (settings.SPLIT_SENTENCES) {
            const sentences = NLP.splitSentences(html);
            //console.log(sentences);
            var newhtml = '';
            for (let sent of sentences) {


                newhtml += `<span id="${genID()}" class="tts-chunk" >${sent.text.replace(/\s+/g, ' ')} </span>`;
            }
        }
        else {
            var newhtml = html;
        }

        if (c.previousSibling && c.previousSibling.nodeType === 1) {
            c.previousSibling.outerHTML = newhtml;
            c.remove();
            if (c.nextSibling) c.nextSibling.remove;
            return;
        }
        if (c.nextSibling && c.nextSibling.nodeType === 1) {
            c.nextSibling.outerHTML = newhtml;
            c.remove();
            if (c.previousSibling) c.previousSibling.remove;
            return;
        }
    });


    [...para.children].forEach((child: HTMLElement) => {
        if (child.tagName == 'SPAN' && child.classList.contains('tts-chunk')) {
            [...child.querySelectorAll('span[voice]')] //find sub voice spans and marge them with parent
                .forEach(span => {
                    span.outerHTML = span.innerHTML;
                });
        }

        if (!curSpan) {
            if (child.tagName == 'SPAN') curSpan = child;

            else { //first child not a span, check if it's an SSML tag with span child
                console.log('c1');
                if (child.childNodes.length == 1 && child.firstChild.nodeType === 1 && child.firstChild.tagName == 'SPAN') {
                    const childSpan = child.firstChild;
                    const cloneSpan = document.createElement('span');
                    [...childSpan.attributes].forEach(attr => cloneSpan.setAttribute(attr.name, attr.value));

                    childSpan.outerHTML = childSpan.innerHTML; //unwrap child span;
                    cloneSpan.innerHTML = child.outerHTML; //wrap the SSML tag with the cloned span

                    child.parentElement.insertBefore(cloneSpan, child);

                    child.remove(); //we just placed a cloned span wraping the content of this child, we can remove it now

                    curSpan = cloneSpan;
                }
            }

            return;
        }
        if (child.tagName != 'SPAN') {
            [...child.childNodes].forEach((node: HTMLElement) => {
                if (node.nodeType === 3) //handle text node
                {
                    curSpan.appendChild(node);
                    return;
                }

                if (node.tagName == 'SPAN') {
                    console.log('c2')
                    const parent = child.parentElement;
                    const childcopy = document.createElement(child.tagName);
                    [...child.attributes].forEach(attr => childcopy.setAttribute(attr.name, attr.value)); //copy attributes

                    const span = node;
                    if (span.id != curSpan.id) {
                        childcopy.innerHTML = span.innerHTML;
                        span.innerHTML = '';
                        span.appendChild(childcopy);
                        parent.insertBefore(span, child);

                        curSpan = span;
                        return
                    }
                    else {
                        childcopy.innerHTML = span.innerHTML;

                        curSpan.appendChild(childcopy);
                    }


                }
            })
            child.remove();
            return;
        }

        if (child.tagName == 'SPAN') {

            const equalClass = [...curSpan.classList].sort().toString().toLowerCase() == [...child.classList].sort().toString().toLowerCase();

            const equalAttr =
                [...curSpan.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()
                ==
                [...child.attributes].filter(attr => attr.name != 'class' && attr.name != 'style').map(attr => attr.name + ':' + attr.value).sort().toString()


            if (curSpan.id == child.id && equalClass && equalAttr) { //if same span merge them
                //console.log('same ID', curSpan.id, curSpan, child.firstChild);
                [...child.childNodes].forEach(node => curSpan.appendChild(node));
                child.remove()

                sunEditor.util.mergeSameTags(curSpan, [[1, 0][1, 0]], true) //then merge their children
            }
            else {
                //console.log('different ID');
                curSpan = child;
            }


            return;
        }


    })
}





function ssml2json(xmlStr: string) {
    var div = document.createElement('div');
    div.innerHTML = xmlStr;

    //console.log(toDOM(toJSON(div)).innerHTML);
    return xml2js(div.innerHTML);
}

function normalizeHTMLSpaces(str: string) {
    return str.replace(/[\f\n\r\t\v ]{2,}/g, ' ');
}