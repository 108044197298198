

import { updateSSML, flattenSSMLNode } from '../utils/ttstools'

const tagName = 'sub';
const commandName = `ssml-${tagName}`;


export default {
    name: commandName,
    display: 'command',

    title: 'Sub',

    // This icon uses Font Awesome
    innerHTML: '<i class="mif-settings-ethernet"></i>',
    buttonClass: 'ui button tbtn-sub',
    add: function (core, targetElement) {
        const context = core.context;
        context[commandName] = {
            targetButton: targetElement
        };


        const span = document.createElement('span')
        span.className = 'txt';
        span.innerText = `[abr]`;
        targetElement.appendChild(span);

    },
    active: function (element) {
        const curRange = this.getRange();

        var regex = new RegExp(`^${tagName}$`, 'i')
        if (!element) {
            this.util.removeClass(this.context[commandName].targetButton, 'active');
        } else if (regex.test(element.nodeName)) {
            this.util.addClass(this.context[commandName].targetButton, 'active');
            return true;
        }

        return false;
    },
    action: function () {
        return false;
        const curRange = this.getRange();
        if (curRange.collapsed) return false;


        this.history.push();
        this.history._lock = true;

        const create = curRange.startContainer.nodeName != tagName.toUpperCase() || !this.util.hasClass(this.context[commandName].targetButton, 'active');
        if (create) {



        } else {




        }

        this.history._lock = false;
        updateSSML(tagName);


        this.history.push();

    }
}
